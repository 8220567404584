import React, { useCallback } from 'react';
import { Table } from 'semantic-ui-react';
import {
  Text,
  Select,
  CheckBox,
} from 'components';

import NewRow from './components/rows/NewRow';
import LegacyRow from './components/rows/LegacyRow';

const allTestStatus = [
  {
    text: <Text id="results_inbox.all">All</Text>,
    key: 'all',
    value: 'all',
  },
  {
    text: <Text id="results_inbox.normal">Normal</Text>,
    key: 'normal',
    value: 'normal',
  },
  {
    text: <Text id="results_inbox.abnormal">Abnormal</Text>,
    key: 'abnormal',
    value: 'abnormal',
  },
];

const ResultsTable_Table = ({ clients, selectedClient, onSelectClient, selectedTestStatus, results, filteredData, searchParams, selectedRows, isMobile, hasPatientColumn, clientInformation, onRowSelect, onRowClick, customUrlClient, selectAllRows, onSelectStatus }) => {

  const renderTableData = useCallback(() => {
    return filteredData.map((result) => {
      return (
        <>
          {result.version === 'fhir' ? (
            <NewRow
              result={result}
              isMobile={isMobile}
              onRowClick={onRowClick}
              onRowSelect={onRowSelect}
              hasPatientColumn={hasPatientColumn}
              selectedRows={selectedRows}
              customUrlClient={customUrlClient}
              clientInformation={clientInformation}
            />
          ) : (
            <LegacyRow
              result={result}
              isMobile={isMobile}
              onRowClick={onRowClick}
              onRowSelect={onRowSelect}
              hasPatientColumn={hasPatientColumn}
              selectedRows={selectedRows}
              customUrlClient={customUrlClient}
              clientInformation={clientInformation}
            />
          )}
        </>
      );
    });
  }, [filteredData, selectedRows]);

  return (
    <div className="results-table__table-wrapper">
      <Table selectable unstackable={!isMobile}>
        <Table.Header>
          <Table.Row>
            {!isMobile && (
              <Table.HeaderCell className="results-table__th">
                <div onClick={(e) => selectAllRows(e)}>
                  <CheckBox
                    className="results-table__checkbox"
                    checked={
                      Object.keys(selectedRows)?.length === filteredData?.length
                    }
                  />
                </div>
              </Table.HeaderCell>
            )}
            {hasPatientColumn && (
              <Table.HeaderCell className="results-table__th">
                <Text size="tiny" id="results_inbox.patient" dark>
                  Patient
                </Text>
              </Table.HeaderCell>
            )}
            {/* <Table.HeaderCell className='results-table__th'>Pass</Table.HeaderCell> */}
            <Table.HeaderCell className="results-table__th">
              <Text size="tiny" id="results_inbox.pass_no/ssn" dark>
                Pass No/SSN
              </Text>
            </Table.HeaderCell>
            <Table.HeaderCell className="results-table__th">
              <Text size="tiny" id="results_inbox.order_id" dark>
                Order ID
              </Text>
              {/* {customUrlClient === 'sIdzwYsvwehphIHNG2ce'
                ? 'Order Id'
                : 'ACSN'} */}
            </Table.HeaderCell>
            <Table.HeaderCell className="results-table__th">
              <Text size="tiny" id="results_inbox.test/profiles" dark>
                Tests/Profiles
              </Text>
            </Table.HeaderCell>
            <Table.HeaderCell style={{ padding: '6px 10px' }}>
              {/* <Select
                  border
                  size="fullWidth"
                  placeholder="Filter by clinic"
                  value={selectedClient}
                  onChange={(e, data) => onSelectClient(data)}
                  search
                  selection
                  options={clients}
                  selectClassName="sample-collection__select-text"
                /> */}
              <Text size="tiny" id="results_inbox.clinic" dark>
                Clinic
              </Text>
            </Table.HeaderCell>

            {/* {!isMobile && (
              <Table.HeaderCell className="results-table__th">
                Tests/Profiles
              </Table.HeaderCell>
            )} */}
            <Table.HeaderCell className="results-table__th">
              <Text size="tiny" id="results_inbox.reported" dark>
                Reported
              </Text>
            </Table.HeaderCell>
            {!isMobile && (
              <Table.HeaderCell className="results-table__th">
                <Text size="tiny" id="results_inbox.status" dark>
                  Status
                </Text>
              </Table.HeaderCell>
            )}
            {customUrlClient !== 'sIdzwYsvwehphIHNG2ce' && (
              <>
                {!isMobile ? (
                  <Table.HeaderCell style={{ padding: '6px 10px' }}>
                    <Select
                      border
                      size="tiny"
                      placeholder="Filter by status"
                      value={searchParams.testStatus}
                      onChange={(e, data) => onSelectStatus(data)}
                      search
                      selection
                      options={allTestStatus}
                      selectClassName="sample-collection__select-text"
                    />
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell className="results-table__th">
                    <Text size="tiny" id="results_inbox.status" dark>
                      Status
                    </Text>
                  </Table.HeaderCell>
                )}
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{!!filteredData?.length && renderTableData()}</Table.Body>
      </Table>
    </div>
  );
}

export default ResultsTable_Table