import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Icon as SIcon } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useCustomForm } from 'hooks';
import { useSelector } from 'react-redux';

import { Text, Button, Icon, ErrorToast, SuccessToast } from 'components';

import HealthDetail from '../../../../patientDetails/components/HealthDetail';
import { sortByDateFunction } from 'utils/sort';

import RiskAssessment from 'components/RiskAssessment/RiskAssessmentCompact/RiskAssessmentCompact';
import AdditionalReports from './components/AdditionalReports/AdditionalReports';

import BmiVisual from '../../../../patientPages/bmiPage/components/BmiVisual';
import BpVisual from '../../../../patientPages/bloodPressurePage/components/BpVisual';
import OxyVisual from '../../../../patientPages/oxyPage/components/OxyVisual';
import VisualAcuity from 'pages/patientDetails/components/visualAcuity/VisualAcuity';
import Accordion from 'features/Accordion/Accordion';
import DoctorsNotes from 'pages/diagnosticReport/components/doctorsNotes/DoctorsNotes';
import SmartReportPreview from 'pages/diagnosticReport/components/smartReportPreview/SmartReportPreview';
import PhysicalExaminationFilter from 'features/PhysicalExaminationFilter/PhysicalExaminationFilter';
import SmartReportWrapper from 'pages/smartReport/SmartReportWrapper';
import BiomarkerOverview from 'pages/diagnosticReport/BiomarkerOverview';
import { vitalSchema } from 'pages/patientDetails/schema';

import QrCodeModal from '../../../../webAppointments/overview/referralsOverview/components/qrCodeModal/qrCodeModal';

import ReactHealthIcon from 'assets/new/reactHealth';
import tinyBlueSvg from 'assets/new/tinyBlueRiverrLogo.svg';

import {
  observationsByCat,
  mapObservationsByStatus,
  convertAnomalyResult,
  convertToDisplayedObservation,
  sortByStatus
} from 'api/fhir/observation/observationMapper';
import patientReactSessionApi from 'api/patientReactSession';
import PatientService from 'api/patient';
import medicalProfileApi from 'api/patientOverview/medicalProfileApi';
import medicalProfileProviderApi from 'api/patientOverview/clientApi/medicalProlileProviderApi';
import resultApi from 'api/result/resultApi';
import { observationApi } from 'api/fhir/observation/observationApi';
import patientApi from 'api/patient/patientApi';

import cardioSvg from 'assets/new/patientApp/cardiovascular.svg';
import chdSvg from 'assets/new/patientApp/chd.svg';
import kidneySvg from 'assets/new/patientApp/kidney.svg';
import diabetesSvg from 'assets/new/patientApp/diabetes.svg';
import hypertensionSvg from 'assets/new/patientApp/hypertension.svg';
import naflSvg from 'assets/new/patientApp/nafl.svg';

import history from '../../../../../history';

import './patientReactSessionDetails.scss';

const Patients = ({ match, location }) => {
  const clientInfo = useSelector((state) => state.general.clientInformation);

  const [patientDetails, setPatientDetails] = useState({});
  const [diagnosticReport, setDiagnosticReport] = useState({});
  const [fetchingDiagnosticReport, setFetchingDiagnosticReport] =
    useState(true);
  const [session, setSession] = useState({});
  const [sessionReports, setSessionReports] = useState(null);
  const [isEditHealthDetails, setIsEditHealthDetails] = useState(false);
  const [physicalExaminationActive, setPhysicalExaminationActive] =
    useState('vitals');
  const [isActionLoading, setActionLoading] = useState(false);
  const [fetchingPatient, setFetchingPatient] = useState(false);
  const [patientPhoto, setPatientPhoto] = useState(null);
  const [medProfile, setMedProfile] = useState(null);
  const [healthDetails, setHealthDetails] = useState([]);
  const [editedHealthDetails, setEditedHealthDetails] = useState({});
  const [visualAcuityDetails, setVisualAcuityDetails] = useState({});
  const [editedVisualAcuityDetails, setEditedVisualAcuityDetails] =
    useState(null);
  const [riskModelsSummary, setriskModelsSummary] = useState(null);
  const [observations, setObservations] = useState([]);
  const [observationsByStatus, setObservationsByStatus] = useState({});
  const [filteredObservations, setFilteredObservations] = useState([]);
  const [categoryDescriptions, setCategoryDescriptions] = useState({});
  const [filteredObservationsByCat, setFilteredObservationsByCat] = useState(
    {}
  );
  const [isSmartModalToggled, toggleSmartReport] = useState(false);
  const [obsByCat, setObsByCat] = useState({});
  const [vitals, setVitals] = useState(null);
  const [reRenderTrigger, setRerenderTrigger] = useState(null);
  const [hasRiskModels, setHasRiskModels] = useState(false);
  const [openOneAccordion, setOpenOneAccordion] = useState(null);
  const [isSavingHealthDetails, setIsSavingHealthDetails] = useState(false);

  const [showQrModal, setShowQrModal] = useState(false);

  const [latestBmi, setLatestBmi] = useState({});
  const [latestBloodPressure, setLatestBloodPressure] = useState({});
  const [latestPulseOx, setLatestPulseOx] = useState({});
  const [healthQuestionnaireEmailActivity, setHealthQuestionnaireEmailActivity] = useState({});
  const [hasRiskModelsValue, setHasRiskModelsValue] = useState(false);
  useEffect(() => {
    if (!medProfile) return;
    setLatestBmi(getLatestVital(medProfile?.bmiList));
    setLatestBloodPressure(getLatestVital(medProfile?.bloodPressureList));
    setLatestPulseOx(getLatestVital(medProfile?.pulseOxList));
  }, [medProfile]);


  const visualAcuityFields = [
    'snellenTestVAid',
    'snellenTestOd',
    'snellenTestOs',
    'visualRefractionTestOd',
    'visualRefractionTestOs',
    'visualRefractionTestRemark',
    'colorBlindness',
    'colorBlindnessRemark',
    'eyePressure',
  ];

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useCustomForm(vitalSchema, {
    weight: '',
    height: '',
    systolicUpperNumber: '',
    diastolicLowerNumber: '',
    heartRate: '',
    pulseOximeter: '',
    perfusionIndex: '',
  });

  useEffect(() => {
    fetchSession();

    return () => {
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    if (!session._id) return;
    if(!patientDetails.id || (patientDetails.id !== session?.patientId)) fetchPatient();
  }, [session]);

  useEffect(() => {
    if(!!sessionReports?.length && !!patientDetails) {
      fetchBiomarkers()
      fetchObservationsOfReport()
    }
  }, [sessionReports, patientDetails]);

  useEffect(() => {
    if (!session._id) return;
  }, [patientDetails]);

  useEffect(() => {
    if (location?.state?.fromReactSession) reactSessionFlow();
  }, [location.state]);

  useEffect(() => {
    const byStatus = mapObservationsByStatus(observations);
    setObservationsByStatus(byStatus);
    async function fetchCategoryDescriptions(observations = []) {
      if (observations.length === 0) {
        return;
      }
      const categoryIds = observations
        .map((observation) => observation.categoryId)
        .filter(Boolean);

      const uniqueCategoryIds = [...new Set(categoryIds)];
      const categoryDescriptions = await observationApi.getCategoryDescriptions(
        uniqueCategoryIds
      );
      setCategoryDescriptions(categoryDescriptions);
    }
    fetchCategoryDescriptions(observations);
  }, [observations]);

  const fetchBiomarkers = () => {
    const resIds = [];
    sessionReports.forEach(res => {
      if(res.version === 'fhir') resIds.push(res.id);
    });
    if(!!resIds?.length) fetchResultsBiomarkers(resIds);
  }

  function getLatestVital(vitalList = []) {
    if (!vitalList.length) return null;
    const sortedList = vitalList.sort(sortByDateFunction);
    return sortedList[0];
  }

  const onResultUploaded = async () => {
    try {
      const pSession = await patientReactSessionApi.getPatientReactSessionById(
        match.params.id
      );
      setSession(pSession.data);
      fetchSessionReports(pSession.data);
      fetchResult(pSession.data.result.id);
    } catch (error) {
      console.log('error', error);
      // toast.error(<ErrorToast error={error} />, { toastId: 'fetch-err' });
    }
  };

  const fetchSession = async () => {
    try {
      const pSession = await patientReactSessionApi.getPatientReactSessionById(
        match.params.id
      );
      setSession(pSession.data);
      fetchSessionReports(pSession.data);
      // pSession.data?.resultIds[0]?.accNo i don't really understand this part
      const resultId = pSession.data?.result?.id || pSession.data?.resultIds[0]?.accNo;
      fetchResult(resultId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />, { toastId: 'fetch-err' });
    }
  };

  const fetchSessionReports = async (pSession) => {
    try {
      const resultIds = pSession?.resultIds.map((resultId) => resultId?.accNo);
      const resultsResponseData = await resultApi.getResultsByIds(resultIds);
      if (resultsResponseData) {
        setSessionReports(resultsResponseData);
      }
    } catch (error) {
      toast.error(<ErrorToast error={error} />, { toastId: 'fetch-err' });
    }
  };

  const categorizeVASnellenTest = (result) => {
    if (result === 'Line 8 - 20/20') {
      return { text: 'Good', colorCode: '#12b001' };
    } else if (
      result === 'Line 7 - 20/25' ||
      result === 'Line 6 - 20/30' ||
      result === 'Line 5 - 20/40'
    ) {
      return { text: 'Fair', colorCode: '#12b001' };
    } else {
      return { text: 'Poor', colorCode: '#ffd443' };
    }
  };

  const getVitals = async (id) => {
    try {
      const vitals = await medicalProfileApi.getAllVitals(id);
      const hasSnellenTest = Boolean(
        vitals?.visualAcuity?.snellenTestVAid ||
          vitals?.visualAcuity?.snellenTestOd ||
          vitals?.visualAcuity?.snellenTestOs
      );
      const hasVisualRefractionTest = Boolean(
        vitals?.visualAcuity?.visualRefractionTestOd ||
          vitals?.visualAcuity?.visualRefractionTestOs
      );
      const hasColorBlindness = Boolean(vitals?.visualAcuity?.colorBlindness);
      const hasEyePressure = Boolean(vitals?.visualAcuity?.eyePressure);
      vitals.visualAcuity = {
        ...vitals.visualAcuity,
        hasSnellenTest,
        hasVisualRefractionTest,
        hasColorBlindness,
        hasEyePressure,
        snellenTestOdCategory: categorizeVASnellenTest(
          vitals.visualAcuity?.snellenTestOd
        ),
        snellenTestOsCategory: categorizeVASnellenTest(
          vitals.visualAcuity?.snellenTestOs
        ),
      };
      setVitals(vitals);
    } catch (error) {
      console.log('error', error);
    }
  };

  // TODO: Move this to only fetch if react report preview is opened, don't forget to move state and related functions,
  // then clean up this component
  const fetchObservationsOfReport = async () => {
    const resIds = [];
    const graphDataByBiomarkerId = await fetchPatientBiomarkerOverview(
      patientDetails.fhirId
    );

    sessionReports.forEach(res => {
      if(res.version === 'fhir') resIds.push(res.id);
    });

    if(!resIds || !resIds.length) return;

    const {data: observationIds} = await resultApi.getObservationsByResultIds(resIds);

    if (!observationIds) return;
    const rawObservationsWithDups = await resultApi.getObservationByIds(observationIds);

    const rawObservations = rawObservationsWithDups.reduce((acc, current) => {
      if(!acc.some(obj => obj.anomalyResult.loinc+obj.anomalyResult.unit === current.anomalyResult.loinc+current.anomalyResult.unit)) acc.push(current);
      return acc;
    }, []);
    // const rawObservations = rawObservationsWithDups;

    const biomarkers = rawObservations
      ? rawObservations.map((obs) => obs?.anomalyResult?.biomarker)
      : [];

    const bioById = {};
    biomarkers.forEach((bio) => {
      if (!bio) return;
      bioById[bio.observation_id] = bio;
    });
    const displayedObservationsUnsorted = rawObservations
      ? rawObservations
          .filter((o) => !!o?.rawResource)
          ?.map((rawObservation) =>
            convertToDisplayedObservation(
              rawObservation,
              graphDataByBiomarkerId
            )
          )
      : [];

    const displayedObservations = sortByStatus(displayedObservationsUnsorted);

    setObservations(displayedObservations);
    const byCat = observationsByCat(displayedObservations, bioById);
    setFilteredObservationsByCat(byCat);
    setFilteredObservations(displayedObservations);
  };

  // TODO: Move this to only fetch if react report preview is opened, don't forget to move state and related functions,
  // then clean up this component
  const fetchPatientBiomarkerOverview = async (patientId) => {
    try {
      const { message: biomarkersById } =
        await patientApi.getPatientBiomarkerOverview(patientId);
      if (biomarkersById.client_id && biomarkersById.clinic_status) return;
      const biomarkerIds = Object.keys(biomarkersById);
      const allDataPoints = {};
      biomarkerIds.forEach((id) => {
        const biomarkerArr = biomarkersById[id];
        const filteredbiomarkerArr = biomarkerArr?.filter((biomarker) =>
          moment(biomarker?.reported_date).isValid()
        );

        if (
          !Array.isArray(filteredbiomarkerArr) ||
          filteredbiomarkerArr?.length === 0
        )
          return;
        const dataPoints = filteredbiomarkerArr.map((biomarker) => {
          return {
            value: isNaN(biomarker?.value)
              ? biomarker?.value
              : Number(biomarker?.value).toFixed(2),
            date: biomarker?.reported_date,
          };
        });
        allDataPoints[id] = dataPoints;
      });

      return allDataPoints;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  };

  const fetchResult = async (id) => {
    try {
      const result = await resultApi.getResult(id);
      if (!result) return;
      setDiagnosticReport(result);
      // fetchObservationsOfReport(result);
    } catch (error) {
      toast.error(<ErrorToast error={error} />, { toastId: 'fetch-err' });
    } finally {
      setFetchingDiagnosticReport(false);
    }
  };

  const fetchResultsBiomarkers = async (resultIds) => {
    try {
      const { data: biomarkersById } =
        await resultApi.getBiomarkersByResultIds(resultIds);

        if(biomarkersById.client_id && biomarkersById.clinic_status) return;

      const biomarkerIds = Object.keys(biomarkersById);
      const categories = {};
      const displayedObservations = [];

      biomarkerIds.forEach((id) => {
        const biomarkerArr = biomarkersById[id];
        const filteredbiomarkerArr = biomarkerArr?.filter((biomarker) => moment(biomarker?.reported_date).isValid())
        const lastBiomarker = filteredbiomarkerArr?.at(-1);

        if (!categories[lastBiomarker?.category_id])
          categories[lastBiomarker?.category_id] = {
            category: lastBiomarker?.category,
          };

        const displayedObservation = {
          id: lastBiomarker?.key || '',
          display: lastBiomarker?.name || '',
          code: lastBiomarker?.loinc || '',
          effectiveDateTime: lastBiomarker?.created_date || '',
          unit:
            !lastBiomarker?.unit || lastBiomarker?.unit === 'NA'
              ? null
              : lastBiomarker?.unit,
          value: isNaN(lastBiomarker?.value)
            ? lastBiomarker?.value
            : Number(lastBiomarker?.value).toFixed(2),
          category: lastBiomarker?.category,
          categoryId: lastBiomarker?.category_id,
          anomalyResult: convertAnomalyResult(lastBiomarker),
        };

        if ((!Array.isArray(filteredbiomarkerArr) || filteredbiomarkerArr?.length === 0)) return;

        const dataPoints = filteredbiomarkerArr.map((biomarker) => {
          return {
            value: isNaN(biomarker?.value)
              ? biomarker?.value
              : Number(biomarker?.value).toFixed(2),
            date: biomarker?.reported_date,
          };
        });

        displayedObservation.dataPoints = dataPoints;

        displayedObservations.push(displayedObservation);
      });

      const byCat = observationsByCat(displayedObservations);
      setCategoryDescriptions(categories);
      setObsByCat(byCat);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const reactSessionFlow = () => {
    toast.info(
      <Text color="white" bold>
        Update patient vitals in the "Personal Health Details" section
      </Text>,
      {
        toastId: 'pat-sess',
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        closeButton: false,
      }
    );
  };

  const getRiskModelsSummary = async (id) => {
    const { data: resp } = await medicalProfileApi.getAllRiskModelsData({ id });
    setriskModelsSummary(resp);
    checkForRiskAssessment(resp);

    let riskModelsValue = false;
    Object.keys(resp).forEach((key) => {
      if (!!resp[key]?.isSuccess)
        riskModelsValue = true;
    });
    setHasRiskModelsValue(riskModelsValue);
  };

  const checkForRiskAssessment = (resp) => {
    if (!resp) return;
    let hasModels = false;
    const keys = Object.keys(resp);
    keys.forEach((key) => {
      if (resp[key].status !== 'failed') hasModels = true;
    });
    setHasRiskModels(hasModels);
  };

  const fetchMedicalProfile = async (id) => {
    try {
      const profile = await medicalProfileProviderApi.getMedicalProfile(
        id.replace('share-', '')
      );
      setMedProfile(profile);
      patHealthDetails(profile);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchPatient = async () => {
    if (!session?.patientId) return;
    try {
      setFetchingPatient(true);
      const { data } = await PatientService.getPatient(session.patientId);
      setPatientPhoto(data?.profilePic);
      setPatientDetails(data);
      if (data?.id) {
        await fetchMedicalProfile(data.id);
        await getRiskModelsSummary(data.id);
        await getVitals(data.id);
        fetchReactQuestionnaireStatus(data.id);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setFetchingPatient(false);
    }
  };

  const calcCompletion = () => {
    let count = 0;
    if (session?.questionnaire?.status === 'completed') count += 1;
    if (session?.vitalsStatus === 'completed') count += 1;
    if (!!session?.result?.id) count += 1;
    if (!!session?.isReviewed?.status) count += 1;
    if (!!session?.reportId) count += 1;

    return count;
  };

  const patHealthDetails = (patient) => {
    const {
      height,
      weight,
      heartRate,
      systolicUpperNumber,
      diastolicLowerNumber,
      pulseOximeter,
      perfusionIndex,
      visualAcuity,
    } = medicalProfileProviderApi.extractLatestHealthDetails(patient);
    const patientHealthDetails = [
      {
        title: 'Height',
        field: 'height',
        value: height,
        unit: 'cm',
        hasValue: !!height,
      },
      {
        title: 'Weight',
        field: 'weight',
        value: weight,
        unit: 'kg',
        hasValue: !!weight,
      },
      {
        title: 'Systolic BP',
        field: 'systolicUpperNumber',
        value: systolicUpperNumber,
        unit: 'mm Hg',
        hasValue: !!systolicUpperNumber,
      },
      {
        title: 'Diastolic BP',
        field: 'diastolicLowerNumber',
        value: diastolicLowerNumber,
        unit: 'mmHg',
        hasValue: !!diastolicLowerNumber,
      },
      {
        title: 'Heart Rate',
        field: 'heartRate',
        value: heartRate,
        unit: 'bpm',
        hasValue: !!heartRate,
      },
      {
        title: 'Pulse OX (SpO2)',
        field: 'pulseOximeter',
        value: pulseOximeter,
        unit: '%',
        hasValue: !!pulseOximeter,
      },
      {
        title: 'Perfusion Index',
        field: 'perfusionIndex',
        value: perfusionIndex,
        unit: '%',
        hasValue: !!perfusionIndex,
      },
      // {
      //   title: 'Respiratory Rate',
      //   field: 'respiratoryRate',
      //   value: patient.resp,
      //   unit: 'bpm',
      //   hasValue: !!patient.resp,
      // },
    ];

    if (visualAcuity?.id) delete visualAcuity.id;
    if (visualAcuity?.createdAt) delete visualAcuity.createdAt;
    if (visualAcuity?.updatedAt) delete visualAcuity.updatedAt;

    setVisualAcuityDetails(visualAcuity);
    setEditedVisualAcuityDetails(visualAcuity);
    setHealthDetails(patientHealthDetails);
  };

  const completedSections = () => {
    const completed = [];
    const completedVisual = visualAcuityFields?.some(
      (field) => visualAcuityDetails?.[field]
    );
    const completedVitals = healthDetails?.some((item) => item?.hasValue);
    if (completedVisual) completed.push('visual-acuity');
    if (completedVitals) completed.push('vitals');

    return completed;
  };

  const onEditClick = () => {
    history.push({
      pathname: `/patients/create`,
      state: {
        patientDetails: { ...patientDetails, medicalProfile: medProfile },
      },
    });
  };

  const onVADetailChange = (field, value, callback) => {
    const details = editedHealthDetails
      ? { ...editedVisualAcuityDetails, [field]: value }
      : { [field]: value };

    if (String(field).includes('snellenTest'))
      details.snellenTestUpdatedAt = new Date();
    if (String(field).includes('visualRefractionTest'))
      details.visualRefractionTestUpdatedAt = new Date();
    if (String(field).includes('colorBlindness'))
      details.colorBlindnessUpdatedAt = new Date();
    if (String(field).includes('eyePressure'))
      details.eyePressureUpdatedAt = new Date();

    setEditedVisualAcuityDetails(details);
    callback(value);
  };
  const onHDetailChange = (field, value, callback) => {
    const details = { ...editedHealthDetails, [field]: value };
    setEditedHealthDetails(details);
    callback(value);
  };

  const onSaveEditPatientHealthDetails = async () => {
    try {
      setIsSavingHealthDetails(true);
      const latestHealthDetails =
        medicalProfileProviderApi.extractLatestHealthDetails(medProfile);

      const visualAcuityHasChanged =
        JSON.stringify(visualAcuityDetails) !==
        JSON.stringify(editedVisualAcuityDetails);

      if (
        !visualAcuityHasChanged &&
        physicalExaminationActive === 'visual-acuity'
      ) {
        return toast.error(
          <ErrorToast message={'No changes made in Visual Acuity section'} />
        );
      }

      await medicalProfileProviderApi.addHealthDetailsBaseOnUpdatedFields(
        {
          ...editedHealthDetails,
          visualAcuity: visualAcuityHasChanged
            ? { ...visualAcuityDetails, ...editedVisualAcuityDetails }
            : undefined,
        },
        latestHealthDetails,
        patientDetails
      );
      await fetchSession();
      await updatePatientReactSession(match.params.id, {
        ...session,
        vitalsStatus: 'completed',
      });
      setEditedHealthDetails({});
      await fetchMedicalProfile(patientDetails.id);
      setIsEditHealthDetails(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSavingHealthDetails(false);
    }
  };

  const completeVitalsCheck = async () => {
    try {
      await updatePatientReactSession(match.params.id, {
        ...session,
        vitalsStatus: 'completed',
      });
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const getIcon = (name) => {
    if (name === 'cvd') return cardioSvg;
    if (name === 'chd') return chdSvg;
    if (name === 'diabetes') return diabetesSvg;
    if (name === 'hypertension') return hypertensionSvg;
    if (name === 'liver') return naflSvg;
    if (name === 'cardiovascular') return cardioSvg;
    if (name === 'coronaryHeartDisease') return chdSvg;
    if (name === 'kidney_stage3') return kidneySvg;
    if (name === 'kidney_stage5') return kidneySvg;
    if (name === 'goutDisease') return kidneySvg;
  };

  const getNextAction = () => {
    if (session?.questionnaire?.status === 'pending')
      return {
        text: 'Complete health questionnaire',
        icon: 'arrowRight',
        IconType: 'Icon',
        callback: openHealthQuestionnaire,
      };
    if (session.vitalsStatus === 'pending')
      return {
        text: 'Complete Physical Examination',
        icon: 'arrowRight',
        IconType: 'Icon',
        callback: openPhysicalExamination,
      };
    if (!session?.result?.id)
      return { text: 'Wait for results', callback: () => {} };
    if (!session?.isReviewed?.status)
      return {
        text: 'Mark session as reviewed',
        isText: false,
        icon: 'check square outline',
        IconType: 'SIcon',
        callback: markAsReviewed,
      };
    if (
      session?.result?.id &&
      session?.isReviewed?.status &&
      (!session.reportId || !diagnosticReport?.smartReportUrl)
    )
      return {
        text: 'Generate ReACT Report',
        isText: false,
        isGenerate: true,
        callback: () => toggleSmartReport(true),
      };

    if (
      session?.result?.id &&
      session?.isReviewed?.status &&
      (session.reportId || diagnosticReport?.smartReportUrl)
    )
      return {
        text: 'Re-generate ReACT Report',
        isText: false,
        isReGenerate: true,
        callback: () => toggleSmartReport(true),
      };

    return {
      text: 'Re-generate ReACT Report',
      isText: false,
      isReGenerate: true,
      callback: () => toggleSmartReport(true),
    };

    // return { text: 'No action needed', isText: true };
  };

  const openHealthQuestionnaire = async () => {
    //open accordion and bring to view
    setOpenOneAccordion('health-questionnaire');
    document
      .getElementById('health-questionnaire')
      .scrollIntoView({ behavior: 'smooth' });
  };
  const openPhysicalExamination = async () => {
    //open accordion and bring to view
    setOpenOneAccordion('physical-examination');
    document
      .getElementById('physical-examination')
      .scrollIntoView({ behavior: 'smooth' });
  };

  const markAsReviewed = async () => {
    try {
      if (!session) return;
      setActionLoading(true);
      await patientReactSessionApi.bulkReviewReactSession([session._id]);
      await fetchSession();

      //open accordion and bring to view
      setOpenOneAccordion('diagnostic-results');
      document.getElementById('diagnostic-results').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setActionLoading(false);
    }
  };

  const issueReport = async ({ isSendEmail }) => {
    try {
      if (!session) return;
      setActionLoading(true);
      const issueRes = await patientReactSessionApi.issueReport({
        id: session._id,
        isSendEmail,
      });
      setDiagnosticReport({
        ...diagnosticReport,
        smartReportUrl: issueRes.data.data?.pdfPath,
      });
      await fetchSession();

      toast.success(<SuccessToast message="ReACT report generated" />);
      toggleSmartReport(false);

      //open accordion and bring to view
      setOpenOneAccordion('react-report');
      document
        .getElementById('react-report')
        .scrollIntoView({ behavior: 'smooth' });
      setRerenderTrigger(moment().toISOString());
      return issueRes;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setActionLoading(false);
    }
  };

  // const bulkDownloadReports = async () => {
  //   try {
  //     const sessionIds = Object.keys(selectedRows);
  //     if (sessionIds.length === 0) return;
  //   } catch (error) {
  //     toast.error(<ErrorToast error={error} />);
  //   }
  // };

  // const updateValue = (id, value) => {
  //   const parts = participants.map((part) => {
  //     if (part.id === id) return { ...part, addOns: value };
  //     return part;
  //   });
  //   setFilteredParts(parts);
  // };

  const onPlatoClick = async () => {
    try {
      await patientReactSessionApi.syncPlatoLabResult({
        token: clientInfo.platoApiKey,
        db: clientInfo.platoDatabase,
        pReactSessionId: match.params.id,
      })
      await new Promise(resolve => setTimeout(resolve, 3000));
      await onResultUploaded();
      toast.success(<SuccessToast message="Successfully requested, the result may take time to appear" />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const onSgimedClick = async () => {
    try {
      const res = await patientReactSessionApi.syncSgimedLabResult({
        token: clientInfo.sgimedApiKey,
        pReactSessionId: match.params.id,
      });
      if (res.data.status === 400) {
        return toast.error(
          <ErrorToast error={res?.data?.message || 'Failed to get report'} />
        );
      }
      await new Promise((resolve) => setTimeout(resolve, 30000));
      await onResultUploaded();
      toast.success(
        <SuccessToast message="Successfully requested, the result may take time to appear" />
      );
    } catch (error) {
      console.log(error);
      console.log(error.response?.data?.message);
      toast.error(
        <ErrorToast
          error={error.response?.data?.message || 'Failed to get report'}
        />
      );
    }
  };

  const updatePatientReactSession = async (id, data) => {
    try {
      const payload = { ...data };
      if (
        typeof payload.addOns === 'string' ||
        payload.addOns instanceof String
      ) {
        payload.addOns = payload.addOns.split(',');
      }
      await patientReactSessionApi.updatePatientReactSession(id, payload);
      await fetchSession();
      toast.success(<SuccessToast message="Session updated" />, {
        autoClose: 800,
      });
    } catch (error) {
      toast.error(<ErrorToast error={error} />, {
        toastId: 'sess-err',
      });
    }
  };

  const reSendEmail = async () => {
    try {
      await patientReactSessionApi.resendQuestionnaire([match.params.id]);
      toast.success(<SuccessToast message="Emails sent" />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
    fetchReactQuestionnaireStatus(patientDetails.id);
  };

  const fetchReactQuestionnaireStatus = async (patientId) => {
    try {
      const {
        data: { result: res },
      } = await patientReactSessionApi.getPatientReactSessionEmailActivity(
        clientInfo?.clientId,
        patientId,
        session?._id,
        'patient-react-flow'
      );

      if (res?.length < 1) return;
      setHealthQuestionnaireEmailActivity(res?.at(-1));
    } catch (error) {
      console.log('error', error);
    }
  };

  const updateResult = async (value) => {
    try {
      const id = diagnosticReport?.fhirId || session.result?.id;
      await resultApi.updateResult(id, { ...value, aiNote: '' });
      setDiagnosticReport({ ...diagnosticReport, ...value, aiNote: '' });
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const openNewWindow = () => {
    const url = new URL(
      `${window.location.origin}/public/react-questionnaire?sessionId=${session._id}`
    );
    if (!!patientDetails.gender)
      url.searchParams.append('gender', patientDetails.gender);
    if (!!patientDetails.race)
      url.searchParams.append('ethn', patientDetails.race);
    if (!!patientDetails.height)
      url.searchParams.append('height', patientDetails.height);
    if (!!patientDetails.weight)
      url.searchParams.append('weight', patientDetails.weight);

    window.open(url, '_blank');
  };

  return (
    <div className="prsd">
      <QrCodeModal
        header={<Text id="react_grp.questionnaire_qr">Questionnaire QR</Text>}
        show={showQrModal}
        hide={setShowQrModal}
        url={`${window.location.origin}/public/react-questionnaire/${match.params.id}`}
      />
      <SmartReportWrapper
        isReactSession={session._id}
        reactSessionReports={sessionReports}
        isModalToggled={isSmartModalToggled}
        toggleModal={toggleSmartReport}
        patient={patientDetails}
        observations={filteredObservationsByCat}
        categoryDescriptions={categoryDescriptions}
        report={diagnosticReport}
        setDiagnosticReport={setDiagnosticReport}
        riskModelsSummary={riskModelsSummary}
        vitals={vitals}
        setRerenderTrigger={setRerenderTrigger}
        extGenerateReportFunc={issueReport}
        isLoading={isActionLoading}
      />
      <div className="prsd__header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            icon
            circular
            onClick={() => history.goBack()}
            color="blue"
            className="mr-3"
          >
            <Icon name="arrowLeft" color="white" fontSize="1.3em" />
          </Button>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
            }}
          >
            <Text type="pageHeader" className="rhd__header__text__h">
              {/* ReACT Session */}
              ReACT Session - {session?.reactSession?.name}
            </Text>
            {session?.reactSession?.externalId && (
              <Text size="big" className="ml-2">
                #{session?.reactSession?.externalId}
              </Text>
            )}
          </div>
        </div>
        <div className="prsd__review-session-btn">
          <Text dark>Suggested next action:</Text>
          {getNextAction().isText ? (
            <Text size="small">{getNextAction().text}</Text>
          ) : (
            <>
              {getNextAction().isGenerate || getNextAction().isReGenerate ? (
                <Button
                  inverted={getNextAction().isReGenerate}
                  backgroundColor="white"
                  size="fluid"
                  color={getNextAction().isGenerate ? 'blue' : 'black'}
                  onClick={() => getNextAction().callback()}
                  isLoading={isActionLoading}
                  isDisabled={isActionLoading}
                >
                  <ReactHealthIcon
                    className="mr-2"
                    height="20px"
                    width="20px"
                    color={getNextAction().isGenerate ? '#fff' : '#000'}
                  />
                  <Text
                    size="small"
                    color={getNextAction().isGenerate ? 'white' : 'black'}
                  >
                    {getNextAction().text}
                  </Text>
                </Button>
              ) : (
                <Button
                  inverted
                  size="fluid"
                  color="black"
                  onClick={() => getNextAction().callback()}
                  isLoading={isActionLoading}
                  isDisabled={isActionLoading}
                  backgroundColor="none"
                >
                  <Text className="mr-2 ml-2" size="small">
                    {getNextAction().text}
                  </Text>
                  {getNextAction().icon ? (
                    getNextAction().IconType === 'Icon' ? (
                      <Icon name={getNextAction().icon} color="black" />
                    ) : (
                      <SIcon
                        name={getNextAction().icon}
                        className="mb-2"
                        color="black"
                      />
                    )
                  ) : (
                    ''
                  )}
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="prsd__section">
        <div className="prsd__top-details-wrapper">
          <div className="prsd__top-details-wrapper__left">
            {fetchingPatient ? (
              <div className="prsd__details-wrapper__loader-wrapper">
                <Loader
                  active
                  className="prsd__details-wrapper__loader-wrapper__loader"
                  size="small"
                />
              </div>
            ) : (
              <>
                <div className="prsd__patient-photo-wrapper">
                  <div className="prsd__patient-photo-wrapper__pic-name">
                    <div className="prsd__patient-photo">
                      {(patientPhoto || medProfile?.profileUrl) && (
                        <img
                          src={patientPhoto || medProfile?.profileUrl}
                          alt="patient"
                        />
                      )}
                    </div>
                    <div className="prsd__detail">
                      <Text size="big" dark className="ml-1 mb-1" uppercase>
                        {patientDetails.fullName}
                      </Text>
                      {patientDetails.id && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={tinyBlueSvg} alt="logo" />
                          <Text
                            id="patient_overview.riverr_id"
                            color="blue"
                            size="tinniest"
                            className="mr-2"
                          >
                            Riverr ID
                          </Text>
                          <Text color="blue" size="tiny" dark capitalize>
                            {patientDetails.id.substring(0, 3)}-
                            {patientDetails.id.substring(3, 6)}
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                  <Button
                    size="small"
                    onClick={() => onEditClick()}
                    color="blue"
                    inverted
                    backgroundColor="none"
                    textId="react_single_overview.edit"
                  >
                    Edit
                  </Button>
                </div>

                <div className="prsd__details-wrapper__row">
                  {patientDetails.dateOfBirth && (
                    <div>
                      <Text id="patient_overview.birth_date" type="dataLabel">
                        Date of Birth
                      </Text>
                      <Text type="data">
                        {moment(
                          patientDetails.dateOfBirth,
                          'YYYY-MM-DD'
                        ).format('DD-MM-YYYY')}{' '}
                        ({moment().diff(patientDetails.dateOfBirth, 'years')}{' '}
                        years old)
                      </Text>
                    </div>
                  )}

                  {patientDetails.gender && (
                    <div>
                      <Text type="dataLabel" id="react_single_overview.gender">
                        Gender
                      </Text>
                      <Text type="data" uppercase>
                        {patientDetails.gender}
                      </Text>
                    </div>
                  )}
                  {patientDetails.nationality && (
                    <div>
                      <Text
                        type="dataLabel"
                        id="react_single_overview.nationality_"
                      >
                        Nationality
                      </Text>
                      <Text type="data" uppercase>
                        {patientDetails.nationality}
                      </Text>
                    </div>
                  )}

                  {(patientDetails.ssn ||
                    patientDetails?.identified?.socialSecurityNumber) && (
                    <div>
                      <Text
                        id="react_single_overview.nric_fin"
                        type="dataLabel"
                      >
                        NRIC/FIN
                      </Text>
                      <Text type="data">
                        {patientDetails.ssn ||
                          patientDetails?.identified?.socialSecurityNumber}
                      </Text>
                    </div>
                  )}

                  {(patientDetails.passNo ||
                    patientDetails?.identified?.passportNumber) && (
                    <div>
                      <Text
                        id="patient_overview.passport_number"
                        type="dataLabel"
                      >
                        Passport Number
                      </Text>
                      <Text type="data">
                        {patientDetails.passNo ||
                          patientDetails?.identified?.passportNumber}
                      </Text>
                    </div>
                  )}
                  {patientDetails.email && (
                    <div>
                      <Text id="patient_overview.email" type="dataLabel">
                        Email
                      </Text>
                      <Text type="data">{patientDetails.email}</Text>
                    </div>
                  )}

                  {patientDetails.phoneNumber && (
                    <div>
                      <Text id="patient_overview.phone_number" type="dataLabel">
                        Phone Number
                      </Text>
                      <Text type="data">{patientDetails.phoneNumber}</Text>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="prsd__top-details-wrapper__right">
            {!fetchingDiagnosticReport ? (
              <DoctorsNotes
                onSave={updateResult}
                value={diagnosticReport?.doctorNote || diagnosticReport?.aiNote}
                resultId={diagnosticReport?.fhirId || session?.result?.id}
                isExtractingData={
                  diagnosticReport?.anomalyProcessState === 'pending'
                }
                hasResult={!!diagnosticReport?.status}
              />
            ) : (
              <DoctorsNotes
                key={'loading-doctor-note'}
                onSave={updateResult}
                value={'loading...'}
                resultId={''}
                isExtractingData={true}
              />
            )}
          </div>
        </div>
      </div>
      {/* {!!session && (
        <div className="prsd__section">
          <div className="prsd__details-wrapper prsd__status">
            <ReactSessionEmailActivity
              sessionId={session._id}
              clientId={clientInfo?.clientId}
              patientId={session?.patientId}
            />
          </div>
        </div>
      )} */}
      <Accordion
        id="health-questionnaire"
        headerText="Health Questionnaire"
        // headerTextId="react_single_overview.session_status"
        status={session?.questionnaire?.status || 'Incomplete'}
        openOne={openOneAccordion}
        setOpenOne={setOpenOneAccordion}
      >
        <div className="prsd__section">
          <div className="prsd__status__section">
            <div className="prsd__status__section--activity">
              <Text
                // id="react_single_overview.status_summary"
                bold
                size="medium"
                className="mb-4"
              >
                Health Questionnaire Activity
              </Text>
              <div className="prsd__status__section__details">
                <div className="prsd__status__section__details__date">
                  <Text size="small" bold center>
                    Last action:
                  </Text>
                  <Text size="small" center>
                    {session?.updatedAt
                      ? moment(session.updatedAt).format('ll')
                      : '-- ----'}
                  </Text>
                </div>
                <Text
                  size="small"
                  center
                  className="prsd__status__section__details__time"
                >
                  {session?.updatedAt
                    ? moment(session.updatedAt).format('hh:mm A')
                    : '-- --'}
                </Text>
                <Text
                  size="medium"
                  uppercase
                  className="prsd__status__section__details__progress"
                  center
                >
                  <div
                    className={`rhd__dot rhd__dot--${
                      session?.questionnaire?.status === 'completed' ||
                      calcCompletion() === 5
                        ? 'completed'
                        : 'pending-vitals'
                    }`}
                  />
                  {session?.questionnaire?.status === 'completed' ||
                  calcCompletion() === 5
                    ? 'Completed'
                    : 'In Progress'}{' '}
                </Text>
              </div>
              <div className="prsd__status__section__details">
                <div className="prsd__status__section__details__date">
                  <Text size="small" bold center>
                    Last email sent at:
                  </Text>
                  <Text size="small" center>
                    {healthQuestionnaireEmailActivity?.sentAt
                      ? moment(healthQuestionnaireEmailActivity?.sentAt).format(
                          'll'
                        )
                      : '-- ----'}
                  </Text>
                </div>
                <Text
                  size="small"
                  center
                  className="prsd__status__section__details__time"
                >
                  {healthQuestionnaireEmailActivity?.sentAt
                    ? moment(healthQuestionnaireEmailActivity?.sentAt).format(
                        'hh:mm A'
                      )
                    : '-- --'}
                </Text>
                <Text
                  size="medium"
                  uppercase
                  className="prsd__status__section__details__progress"
                  center
                >
                  <div
                    className={`rhd__dot rhd__dot--${
                      healthQuestionnaireEmailActivity?.sentAt
                        ? 'completed'
                        : 'pending-vitals'
                    }`}
                  />
                  {healthQuestionnaireEmailActivity?.sentAt
                    ? 'Sent'
                    : 'Not Sent'}{' '}
                </Text>
              </div>
              <div className="prsd__status__section__details">
                <div className="prsd__status__section__details__date">
                  <Text size="small" bold center>
                    Last email delivered at:
                  </Text>
                  <Text size="small" center>
                    {healthQuestionnaireEmailActivity?.delivered
                      ? moment(
                          healthQuestionnaireEmailActivity?.delivered
                        ).format('ll')
                      : '-- ----'}
                  </Text>
                </div>
                <Text
                  size="small"
                  center
                  className="prsd__status__section__details__time"
                >
                  {healthQuestionnaireEmailActivity?.delivered
                    ? moment(
                        healthQuestionnaireEmailActivity?.delivered
                      ).format('hh:mm A')
                    : '-- --'}
                </Text>
                <Text
                  size="medium"
                  uppercase
                  className="prsd__status__section__details__progress"
                  center
                >
                  <div
                    className={`rhd__dot rhd__dot--${
                      healthQuestionnaireEmailActivity?.delivered
                        ? 'completed'
                        : 'pending-vitals'
                    }`}
                  />
                  {healthQuestionnaireEmailActivity?.delivered
                    ? 'Delivered'
                    : 'Not delivered'}{' '}
                </Text>
              </div>
            </div>
            <div className="prsd__status__section--send-email">
              <Text
                // id="react_single_overview.suggested_action"
                bold
                size="medium"
                className="mb-5"
              >
                ReACT Health Questionnaire
              </Text>
              <div className="prsd__status__section__email-btns">
                <Button onClick={() => reSendEmail()}>
                  <SIcon name="send" color="white" />
                  <Text
                    // id="react_grp.re_send_questionnaire"
                    size="small"
                    bold
                    color="white"
                  >
                    Send via Email
                  </Text>
                </Button>
                <Button onClick={() => openNewWindow()}>
                  <SIcon name="pencil" color="white" />
                  <Text
                    // id="react_grp.re_send_questionnaire"
                    size="small"
                    bold
                    color="white"
                  >
                    View Questionnaire
                  </Text>
                </Button>
                <Button inverted onClick={() => setShowQrModal(true)}>
                  <SIcon name="qrcode" color="black" />
                  <Text
                    // id="react_grp.generate_questionnaire"
                    color="black"
                    size="small"
                    bold
                  >
                    Scan QR
                  </Text>
                </Button>
              </div>
              <div
                style={{
                  marginTop: '15px',
                  background: '#ffffffde',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                <Text color="lightGrey" size="tiny">
                  *You will need to refresh the page in order to see the Health
                  Questionnaire status update to 'Completed'
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Accordion>
      <Accordion
        id="physical-examination"
        headerText="Physical Examination"
        // headerTextId="react_single_overview.vitals"
        status={session?.vitalsStatus || 'Incomplete'}
        openOne={openOneAccordion}
        setOpenOne={setOpenOneAccordion}
      >
        {!fetchingPatient && (
          <div className="prsd__section">
            <PhysicalExaminationFilter
              activeList={physicalExaminationActive}
              setActiveList={setPhysicalExaminationActive}
              completedSections={completedSections()}
            />
            <div className="prsd__details-wrapper">
              {isEditHealthDetails ? (
                <div className="prsd__details-wrapper__buttons">
                  <Button
                    onClick={() => setIsEditHealthDetails(false)}
                    color="red"
                    inverted
                    // textId="react_single_overview.cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSaveEditPatientHealthDetails)}
                    color="green"
                    className="ml-3"
                    isLoading={isSavingHealthDetails}
                    disabled={isSavingHealthDetails}
                    // className="prsd__detail__button"
                    // textId="react_single_overview.save"
                  >
                    Complete examination
                  </Button>
                </div>
              ) : (
                <div className="prsd__details-wrapper__buttons">
                  <Button
                    onClick={() => {
                      setIsEditHealthDetails(true);
                    }}
                    color="blue"
                    // className="prsd__detail__button"
                    // textId="react_single_overview.edit"
                  >
                    Start examination
                  </Button>
                  <Button
                    onClick={() => completeVitalsCheck()}
                    color="green"
                    inverted
                    className="ml-3"
                    // className="prsd__detail__button"
                    // textId="react_single_overview.edit"
                  >
                    Mark as completed
                  </Button>
                </div>
              )}
              {physicalExaminationActive === 'vitals' && (
                <>
                  <div className="prsd__vitals">
                    {healthDetails.map((data) => (
                      <Controller
                        name={data.field}
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <HealthDetail
                            title={data.title}
                            field={data.field}
                            value={value || data.value}
                            unit={data.unit}
                            isEdit={isEditHealthDetails}
                            onChange={({ field, value }) =>
                              onHDetailChange(field, value, onChange)
                            }
                            errors={errors}
                          />
                        )}
                      />
                    ))}
                  </div>
                  {!!latestBmi?.value && (
                    <div className="prsd__vitals-graphs">
                      {medProfile?.bmiList && medProfile?.bmiList[0] && (
                        <div className="prsd__vital-graph">
                          <BmiVisual
                            isClient
                            index={0}
                            row={{
                              height: latestBmi?.height?.value,
                              heightUnit: latestBmi?.height?.unit,
                              weight: latestBmi?.weight?.value,
                              weightUnit: latestBmi?.weight?.unit,
                              bmi: latestBmi?.value,
                              status: latestBmi?.status,
                              date: latestBmi?.updatedAt,
                            }}
                          />
                        </div>
                      )}
                      {!!latestBloodPressure?.status && (
                        <div className="prsd__vital-graph">
                          {/* <Text dark size="big">
                    Blood Pressure
                  </Text> */}
                          <BpVisual
                            isClient
                            index={0}
                            row={{
                              systolic:
                                latestBloodPressure?.systolicUpperNumber,
                              diastolic:
                                latestBloodPressure?.diastolicLowerNumber,
                              pulse: latestBloodPressure?.heartRate,
                              status: latestBloodPressure?.status,
                              date: latestBloodPressure?.updatedAt,
                            }}
                          />
                        </div>
                      )}
                      {!!latestPulseOx?.risk && (
                        <div className="prsd__vital-graph">
                          {/* <Text dark size="big">
                  Pulse Ox
                </Text> */}
                          <OxyVisual
                            isClient
                            index={0}
                            row={{
                              o2: latestPulseOx?.pulseOximeter,
                              pi: latestPulseOx?.perfusionIndex,
                              pulse: latestPulseOx?.heartRate,
                              risk: latestPulseOx?.risk,
                              date: latestPulseOx?.updatedAt,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {physicalExaminationActive === 'visual-acuity' && (
                <div className="prsd__visual-ac">
                  <VisualAcuity
                    categorizeVASnellenTest={categorizeVASnellenTest}
                    data={visualAcuityDetails}
                    editedData={editedVisualAcuityDetails}
                    editMode={isEditHealthDetails}
                    onDetailsChange={onVADetailChange}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Accordion>
      <Accordion
        id="diagnostic-results"
        // xxxx
        headerText="Diagnostic Results"
        // headerTextId="react_report.diagnostic_reports"
        status={session?.resultIds?.length ? 'completed' : 'Incomplete'}
        openOne={openOneAccordion}
        setOpenOne={setOpenOneAccordion}
      >
        {hasRiskModelsValue && (
          <div className="prsd__section mt-2">
            <Text
              id="react_single_overview.risk_assessment"
              bold
              size="big"
              className="prsd__section__sectional-header"
            >
              Risk Assessment
            </Text>

            <div className="prsd__risk-ass-wrapper">
              {Object.keys(riskModelsSummary).map((key) => (
                <>
                  {riskModelsSummary[key]?.data?.calibrated?.score && (
                    <div className="prsd__risk-ass-wrapper__model">
                      <RiskAssessment
                        modelName={key}
                        clientView
                        data={riskModelsSummary[key]?.data}
                        icon={getIcon(key)}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <div className="prsd__section">
          <Text
            className="prsd__section__sectional-header"
            id="react_report.diagnostic_reports"
            bold
            size="big"
          >
            Diagnostic Reports
          </Text>
          <div className="prsd__details-wrapper">
            <AdditionalReports
              onPlatoClick={onPlatoClick}
              onSgimedClick={onSgimedClick}
              patientDetails={patientDetails}
              onFetchAdditionalCallback={onResultUploaded}
              onDelete={onResultUploaded}
            />
          </div>
        </div>
        {!!session?.resultIds?.length && (
          <div className="prsd__section">
            <Text bold size="big" className="prsd__section__sectional-header">
              Biomarker Overview
            </Text>
            {/* <DiagnosticReport
              id={session.result.id}
              isReactSession={session._id}
              reactSessionReports={sessionReports}
              reRenderTrigger={reRenderTrigger}
              setRerenderTrigger={setRerenderTrigger}
            /> */}
            <BiomarkerOverview
              hideTitle={true}
              viewType="table"
              typeSelector={false}
              filteredObservationsByCat={filteredObservationsByCat}
              categoryDescriptions={categoryDescriptions}
            />
          </div>
        )}
      </Accordion>
      <Accordion
        id="react-report"
        headerText="ReACT Report"
        status={
          diagnosticReport?.smartReportUrl ? 'Generated' : 'Not generated'
        }
        openOne={openOneAccordion}
        setOpenOne={setOpenOneAccordion}
      >
        <div className="prsd__section">
          {diagnosticReport?.smartReportUrl ? (
            <SmartReportPreview result={diagnosticReport} />
          ) : (
            <Text>ReACT report has not been generated.</Text>
          )}
        </div>
      </Accordion>
    </div>
  );
};

export default withRouter(Patients);
