import React, { useCallback, useEffect, useState } from 'react';

import {
  GoogleLogin,
  useGoogleOneTapLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { useMediaQuery } from 'react-responsive';

// refresh token
import { refreshTokenSetup } from './googleRefreshToken';
import IdAuthService from '../../../api/identity/authentication';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components';
import jwt from 'jwt-decode';
import IdUserService from '../../../api/identity/user';
import { setUser } from '../../../redux/generalState.actions';
import { createUserStorage } from '../../../auth/authentication';
import history from '../../../history';
import { useDispatch } from 'react-redux';

import { GOOGLE_AUTH_CLIENT_ID } from '../../../constants';

function PatientLoginByGoogle({ type }) {
  const dispatch = useDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    localStorage.setItem('LOGIN_TYPE', 'patient');
  }, []);

  const onSuccess = useCallback(async (res) => {
    setIsLoggingIn(true);
    try {
      const { credential } = res;
      const authResp = await IdAuthService.onSignInUserByGoogle({
        google: { credential },
        device_id: '',
        device_name: 'web',
      });
      if (!authResp) {
        setIsLoggingIn(false);
        return toast.error(
          <ErrorToast message="Login failed, you have entered an invalid username or password." />
        );
      }

      const parsedLocalToken = jwt(authResp.data.access_token);
      const parsedRefreshToken = jwt(authResp.data.id_token);

      const localToken = {
        token: authResp.data.access_token,
        expires: parsedLocalToken.StandardClaims.exp,
        // expires: moment.utc().add(5, 'seconds'),
      };
      const refreshToken = {
        token: authResp.data.id_token,
        expires: parsedRefreshToken.StandardClaims.exp,
        // expires: moment.utc().add(10, 'seconds'),
      };
      localStorage.setItem('AUTH_TOKEN', JSON.stringify(localToken));
      localStorage.setItem('USER_ID', authResp.data.user_id);
      localStorage.setItem('REFRESH_TOKEN', JSON.stringify(refreshToken));
      const {
        data: { user },
      } = await IdUserService.getCurrentUser();
      if (user.metadata?.role === 'patient' && !user?.metadata?.fhirId) {
        const patient =
          await patientApi.createPatientByIdentityUserIfNotExist();
        if (patient?.fhirId) {
          const fhirId = patient.fhirId;
          user.metadata.fhirId = fhirId;
          await userApi.updateMetadata({ fhirId });
        }
      }
      dispatch(setUser(user));
      await createUserStorage(user);
      refreshTokenSetup(res);
      if (user?.metadata?.role === 'patient') return history.push('/p');
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoggingIn(false);
    }
  }, []);

  const onFailure = useCallback((res) => {
    console.log('Login failed: res:', res);
  }, []);

  return (
    // <div className="signup__google">
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <div className="signup__right__google__button mt-4">
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onFailure}
          auto_select={false}
          useOneTap={true}
          width={isMobile ? '280' : '300'}
          // shape="pill"
          text={type === 'login' ? 'signin_with' : 'signup_with'}
        />
      </div>
    </GoogleOAuthProvider>
    // </div>
  );
}

export default PatientLoginByGoogle;
