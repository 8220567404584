import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Text, Button } from 'components';

import arrowRight from 'assets/new/arrowRight-white.svg'
import thumbsUpGuy from 'assets/rhc/incompleteTokens/blue_smiley_token.svg'
// import bmiToken from 'assets/rhc/incompleteTokens/BMI_token.svg'
// import bpToken from 'assets/rhc/incompleteTokens/BP-token.svg'
// import oxyToken from 'assets/rhc/incompleteTokens/SpO2_token.svg'

import allergyToken from 'assets/rhc/incompleteTokens/allergy-token.svg'
import conditionToken from 'assets/rhc/incompleteTokens/condition-token.svg'
import vacToken from 'assets/rhc/incompleteTokens/vaccination-token.svg'
import medsToken from 'assets/rhc/incompleteTokens/medication-token.svg'

import history from '../../history';

import './patientEncourageNotif.scss';

const messages = {
  bmi: {
    icon: thumbsUpGuy,
    header: 'Keep it up!',
    text: 'We have recorded your height and weight.',
  },
  bp: {
    icon: thumbsUpGuy,
    header: 'Nice going!',
    text: 'We have recorded your blood pressure.',
  },
  pulseOx: {
    icon: thumbsUpGuy,
    header: 'Nice going!',
    text: 'We have recorded your Pulse Ox.',
  },
  results: {
    icon: thumbsUpGuy,
    header: 'Keep it up!',
    text: 'We have added your result.',
  },
  condition: {
    icon: conditionToken,
    header: 'Nice going!',
    text: 'We have recorded your medical condition.',
  },
  medication: {
    icon: medsToken,
    header: 'Keep it up!',
    text: 'We have recorded your medication.',
  },
  vaccination: {
    icon: vacToken,
    header: 'Keep it up!',
    text: 'We have recorded your vaccination.',
  },
  allergy: {
    icon: allergyToken,
    header: 'Keep it up!',
    text: 'We have recorded your allergy.',
  },
}

const actions = {
  bmi: {
    text: 'Add BMI Now',
    redirect: '/p/bmi'
  },
  pulseOx: {
    text: 'Add Pulse Ox Now',
    redirect: '/p/oxy'
  },
  bp: {
    text: 'Add Blood Pressure Now',
    redirect: '/p/bp'
  },
  results: {
    text: 'Add Lab Report Now',
    redirect: '/p/results'
  },
  condition: {
    text: 'Add Medical Condition',
    redirect: '/p/records/condition'
  },
  allergy: {
    text: 'Add Allergy Now',
    redirect: '/p/records/allergy'
  },
  medication: {
    text: 'Add Medication Now',
    redirect: '/p/records/meds'
  },
  vaccination: {
    text: 'Add Vaccination Now',
    redirect: '/p/records/vac'
  }
}

const PatientEncourageNotif = ({currentScreen = 'bmi'}) => {
  const [content, setContent] = useState({});
  const [action, setAction] = useState(null);

  const { profileSummary } = useSelector((state) => state.patient);
  const { riskModelsSummary } = useSelector((state) => state.patient);

  useEffect(() => {
    setContent(messages[currentScreen]);
  }, [])

  useEffect(() => {
    handleActionSet();
  }, [])
  const handleActionSet = () => {
    let hasMatch = false
    if(currentScreen === 'bmi' || currentScreen === 'bp' || currentScreen === 'oxy') {
      if(currentScreen !== 'bmi' && !profileSummary?.bmi?.length) { setAction(actions.bmi); hasMatch = true}
      if(currentScreen !== 'bp' && !profileSummary?.bloodPressure?.length) { setAction(actions.bp); hasMatch = true}
      if(currentScreen !== 'oxy' && !profileSummary?.pulseOx?.length) { setAction(actions.pulseOx); hasMatch = true}
    }
    
    if(currentScreen === 'condition' || currentScreen === 'allergy' || currentScreen === 'medication' || currentScreen === 'vaccination') {
      if(currentScreen !== 'condition' && !profileSummary?.conditions?.length) {setAction(actions.condition); hasMatch = true}
      if(currentScreen !== 'vaccination' && !profileSummary?.vaccinations?.length) {setAction(actions.vaccination); hasMatch = true}
      if(currentScreen !== 'medication' && !profileSummary?.medications?.length) {setAction(actions.medication); hasMatch = true}
      if(currentScreen !== 'allergy' && !profileSummary?.allergies?.length) {setAction(actions.allergy); hasMatch = true}
    }
    if(!hasMatch) setAction(null);
  }

  const handleOnClick = () => {
    history.push(action?.redirect);
  }

  return (
    <>
      {!!action ? (
      <div className='p-enc-n'>
        {/* <img className='p-enc-n__bg' src={thumbsUpGuyBg} /> */}
        <div className='p-enc-n__content'>
          <img className='p-enc-n__img' src={content?.icon} />
          <div className='p-enc-n__content__text-wrapper'>
            <div className='p-enc-n__content__text'>
              <Text bold size="big" className='p-enc-n__header'>{content?.header}</Text>
              <Text size="tiny" className='p-enc-n__text'>{content?.text}</Text>
            </div>
            <Button fontSize="tiny" type="pill" size="fluid" className='p-enc-n__button' onClick={handleOnClick}>
              {action?.text}<img className='p-enc-n__arrow' src={arrowRight} />
              </Button>
          </div>
        </div>
      </div>
      ): (
        null
      )}
    </>
  );
};

export default PatientEncourageNotif;
