const API_V1_ENDPOINT = process.env.REACT_APP_API_V1_END_POINT;
const API_V1_FHIR_END_POINT = process.env.REACT_APP_API_V1_FHIR_END_POINT;
const PDF_EXTRACTOR_END_POINT= process.env.REACT_APP_API_V1_PDF_EXTRACTOR_END_POINT;
const GOOGLE_AUTH_CLIENT_ID= process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
const PAYMENT_GATEWAY_ENDPOINT = 'http://localhost:4000/api/v1/gateway/stripe';

const uploadModes = {
  clientLogo: 'clientLogo',
  image: 'image',
  certificateWatermark: 'certificateWatermark',
  certificateLogo: 'certificateLogoUploaded',
  stampImage: 'stampImages',
  certificateInstructorSignature: 'certificateInstructorSignature',
  certificatePhysicianSignature: 'certificatePhysicianSignature',
  certificateIssuerSignature: 'certificateIssuerSignature',
  locationImage: 'locationImage',
  instructorImage: 'instructorImage',
  courseImage: 'courseImage',
  certAttachments: 'certAttachments',
  newAppointmentClientLogo: 'newAppointmentClientLogo',
  patientProfileImage: 'patientProfileImage',
};

// TODO: remove this and make it based on the logged in user
const subdomains = {
  // localhost: '613ae68446debcb0c4ef818b', Test Eurofins account
  localhost: '6217a5e56289886cbf830ceb',
  master: '6217a5e56289886cbf830ceb',
  elab: '618246c4b98cddb71e0b96bd',
  develop: '6217a5e56289886cbf830ceb',
};

const PATIENT_APP_CLIENT = '00000000000000000000';

export {
  subdomains,
  uploadModes,
  API_V1_ENDPOINT,
  API_V1_FHIR_END_POINT,
  PAYMENT_GATEWAY_ENDPOINT,
  PDF_EXTRACTOR_END_POINT,
  PATIENT_APP_CLIENT,
  GOOGLE_AUTH_CLIENT_ID
};
