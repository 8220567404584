import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import {
  observationsByCat,
  convertAnomalyResult,
  convertToDisplayedObservation,
  sortByStatus,
} from 'api/fhir/observation/observationMapper';

import { ErrorToast } from 'components';

import BiomarkerOverview from '../../pages/diagnosticReport/BiomarkerOverview';
import PlaceholderCard from 'features/patientApp/PlaceholderCard/PlaceholderCard';

import biomakerReviewSvg from 'assets/rhc/emptyStates/biomarkerOverview-emptyState.svg';

import patientApi from 'api/patient/patientApi';
import resultApi from 'api/result/resultApi';
import { observationApi } from 'api/fhir/observation/observationApi';

const PatientBiomarkerOverview = ({ patientId, results }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [obsByCat, setObsByCat] = useState({});
  const [categoryDescriptions, setCategoryDescriptions] = useState({});
  const [observations, setObservations] = useState([]);
  const [filteredObservationsByCat, setFilteredObservationsByCat] = useState(
    {}
  );

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (!!patientId) fetchPatientBiomarkers();
  }, [patientId]);

  useEffect(() => {
    async function fetchCategoryDescriptions(observations = []) {
      if (observations.length === 0) {
        return;
      }
      const categoryIds = observations
        .map((observation) => observation.categoryId)
        .filter(Boolean);

      const uniqueCategoryIds = [...new Set(categoryIds)];
      const categoryDescriptions = await observationApi.getCategoryDescriptions(
        uniqueCategoryIds
      );
      setCategoryDescriptions(categoryDescriptions);
    }
    fetchCategoryDescriptions(observations);
  }, [observations]);

  const fetchPatientBiomarkers = async () => {
    try {
      setIsLoading(true);
      const { message: biomarkersById } =
        await patientApi.getPatientBiomarkerOverview(patientId);

      if (biomarkersById.client_id && biomarkersById.clinic_status) return;

      const biomarkerIds = Object.keys(biomarkersById);
      const allDataPoints = {};

      biomarkerIds.forEach((id) => {
        const biomarkerArr = biomarkersById[id];
        const filteredbiomarkerArr = biomarkerArr?.filter((biomarker) =>
          moment(biomarker?.reported_date).isValid()
        );

        if (
          !Array.isArray(filteredbiomarkerArr) ||
          filteredbiomarkerArr?.length === 0
        )
          return;
        const dataPoints = filteredbiomarkerArr.map((biomarker) => {
          return {
            value: isNaN(biomarker?.value)
              ? biomarker?.value
              : Number(biomarker?.value).toFixed(2),
            date: biomarker?.reported_date,
          };
        });
        allDataPoints[id] = dataPoints;
      });

      const graphDataByBiomarkerId = allDataPoints;

      const observationIds = results?.flatMap((item) => item.observations);
      if (!observationIds || !observationIds?.length) return;
      const rawObservations = await resultApi.getObservationByIds(
        observationIds
      );
      const biomarkers = rawObservations
        ? rawObservations.map((obs) => obs?.anomalyResult?.biomarker)
        : [];

      const bioById = {};
      biomarkers.forEach((bio) => {
        if (!bio) return;
        bioById[bio.observation_id] = bio;
      });
      const displayedObservationsUnsorted = rawObservations
        ? rawObservations
            .filter((o) => !!o?.rawResource)
            ?.map((rawObservation) =>
              convertToDisplayedObservation(
                rawObservation,
                graphDataByBiomarkerId
              )
            )
        : [];

      const displayedObservations = sortByStatus(displayedObservationsUnsorted);
      const filterObservations = displayedObservations.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) => t.code === item.code && t.display === item.display
          )
      );
      setObservations(filterObservations);
      const byCat = observationsByCat(filterObservations, bioById);
      setFilteredObservationsByCat(byCat);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="mt-5 mb-5">
          <Loader size="medium" active inline="centered" />
        </div>
      ) : (
        <>
          {Object.keys(obsByCat).length > 0 || !isMobile ? (
            <BiomarkerOverview
              hideTitle={true}
              viewType="table"
              typeSelector={false}
              filteredObservationsByCat={filteredObservationsByCat}
              categoryDescriptions={categoryDescriptions}
            />
          ) : (
            <PlaceholderCard
              icon={biomakerReviewSvg}
              header="Empowers you with health prediction and contextual insights when you need them."
              text="At Riverr, we champion preventive health. Our expanding risk prediction models aim to join you on a journey towards early health awareness, enabling timely interventions and lifestyle adjustments for a better life."
              buttonText="Upload report now"
              redirectPath="/p/results/upload/files"
            />
          )}
        </>
      )}
    </>
  );
};

export default PatientBiomarkerOverview;
