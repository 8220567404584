import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Icon as SIcon, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import { Text, Button, ErrorToast, Icon } from 'components';
import PlaceholderCard from 'features/patientApp/PlaceholderCard/PlaceholderCard';
import PageHeader from '../components/PageHeader/PageHeader';
import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import HealthValueLineWrapper from 'features/HealthValueLine/HealthValueLinesWrapper';
import PatientEncourageNotif from 'features/PatientEncourageNotif/PatientEncourageNotif';

import bpSvg from 'assets/rhc/emptyStates/BP-emptyState.svg';

import history from '../../../history';

import '../bmiPage/bmiPage.scss';
import bloodPressureApi from '../../../api/patientOverview/bloodPressureApi';

const systolicRange = [
  { text: 'Normal', subText: '<120', range: [70, 120], color: 'green' },
  {
    text: 'Elevated',
    subText: '120 - 129',
    range: [120, 129],
    color: 'yellow',
  },
  {
    text: 'High',
    subText: '130 - 139',
    range: [130, 139],
    color: 'orange',
  },
  { text: 'Very High', subText: '140-179', range: [140, 179], color: 'red' },
  { text: 'Hypertensive', subText: '≥180', range: [180, 220], color: 'red2 ' },
];
const diastolicRange = [
  { text: 'Normal', subText: '<80', range: [40, 79], color: 'green' },
  {
    text: 'High',
    subText: '80 - 89',
    range: [80, 89],
    color: 'orange',
  },
  {
    text: 'Very High',
    subText: '90 - 119',
    range: [90, 119],
    color: 'red',
  },
  { text: 'Hypertensive', subText: '≥120', range: [120, 130], color: 'red2' },
];

const heartRateRange = [
  { text: 'High Risk', subText: '<40', range: [29, 39], color: 'red2' },
  { text: 'Normal', subText: '40-100', range: [40, 100], color: 'green' },
  {
    text: 'Borderline',
    subText: '101-109',
    range: [101, 109],
    color: 'yellow',
  },
  {
    text: 'Moderate',
    subText: '110-129',
    range: [110, 129],
    color: 'orange',
  },
  {
    text: 'High Risk',
    subText: '≥130',
    range: [130, 200],
    color: 'red2',
  },
];

const bpColors = {
  Normal: { color: 'green' },
  Elevated: { color: 'yellow' },
  'High Blood Pressure': { color: 'red' },
  'Normal-High Blood Pressure': { color: 'red' },
  'High Blood Pressure (for both hypertension 1 and 2)': { color: 'red' },
  'Hypertension Crisis': { color: 'red2 ' },
};

const OxyPage = ({ location }) => {
  const queryLimit = new URLSearchParams(history.location.search).get('limit');
  const initialLoadLimit = !!queryLimit ? Number(queryLimit) : 3;
  const additionalLoadLimit = 3;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLodingMore] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [loadMoreLimit, setLoadMoreLimit] = useState(initialLoadLimit);
  const [dataToShow, setDataToShow] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const bloodPressureList =
        await bloodPressureApi.getBloodPressuresOfPatient();

      const bpData = [];

      bloodPressureList.forEach((bp) => {
        if (bp.status)
          bpData.push({
            id: bp.id,
            date: bp.date,
            diastolic: bp.diastolicLowerNumber,
            systolic: bp.systolicUpperNumber,
            pulse: bp.heartRate,
            status: bp.status,
            advice: bp.advice,
          });
      });
      setData(bpData);

      if (bpData?.length > initialLoadLimit) {
        setIsMore(true);
      }
      setDataToShow(bpData.slice(0, initialLoadLimit));
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const createRecord = () => {
    history.push('/p/bp/create');
  };

  const loadMore = () => {
    try {
      setIsLodingMore(true);
      let newLimit = loadMoreLimit + additionalLoadLimit;

      if (newLimit > data.length) {
        newLimit = data.length;
      }

      //update url state
      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?limit=${newLimit}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);

      setDataToShow(data?.slice(0, newLimit));
      setLoadMoreLimit(newLimit);
      setIsLodingMore(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="bmi-page">
      {location?.state?.fromCreate && (
        <PatientEncourageNotif currentScreen="bp" />
      )}
      <div className="bmi-page__header">
        <PageHeader
          isLoading={isLoading}
          titleId={
            data.length === 0
              ? 'patient_app.blood_pressure_add'
              : 'react_single_overview.blood_pressure'
          }
          title={data.length === 0 ? 'Add Blood Pressure' : 'Blood Pressure'}
          backPath="/p"
        />
      </div>
      {data.length === 0 && !isLoading && (
        <PlaceholderCard
          icon={bpSvg}
          headerId="patient_app.blood_pressure_hey"
          header="Hey! Haven't logged a blood pressure reading yet? Add one now for a healthier you! "
          buttonText="Add Blood Pressure"
          buttonTextId="patient_app.blood_pressure_add"
          redirectPath="/p/bp/create"
          text="Keep yourself up-to-date on potential health risks such as hypertension and cardiovascular diseases."
          textId="patient_app.blood_pressure_keep"
        />
      )}
      <div className="bmi-page__body">
        {isLoading && (
          <div className="bmi-page__body__list__loader-wrapper">
            <Loader style={{ display: 'flex' }} size="small" />
          </div>
        )}
        {data.length !== 0 && (
          <>
            <div className="bmi-page__body__list-wrapper">
              <div className="bmi-page__body__list">
                {!isLoading && (
                  <>
                    {dataToShow.map((row, index) => (
                      <div className="bmi-page__body__list__item">
                        <HealthValueLineWrapper
                          onClick={() =>
                            history.push({
                              pathname: `/p/bp/edit/${row.id}`,
                              state: { data: row },
                            })
                          }
                          key={index + row.id}
                          title="Overall Score -"
                          createdAt={moment(row.date).format(
                            'DD MMM YYYY, HH:mm'
                          )}
                          value={{
                            value: row.systolic,
                            text: row.status,
                          }}
                          color={bpColors[row.status]?.color || 'green'}
                        >
                          <div style={{ display: 'flex' }}>
                            <div className="bmi-page__body__line-graph-text">
                              <Text size="micro">Systolic</Text>
                              <Text bold>{row.systolic} mm Hg</Text>
                              <Text size="nano" color="lightGrey">
                                Upper Number
                              </Text>
                            </div>
                            <HealthValueLine
                              key={index + row.id}
                              value={{
                                value: row.systolic,
                                text: row.status,
                              }}
                              ranges={systolicRange}
                            />
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div className="bmi-page__body__line-graph-text">
                              <Text size="micro">Diastolic</Text>
                              <Text bold>{row.diastolic} mm Hg</Text>
                              <Text size="nano" color="lightGrey">
                                Lower Number
                              </Text>
                            </div>
                            <HealthValueLine
                              key={index + row.id}
                              value={{
                                value: row.diastolic,
                                text: row.status,
                              }}
                              ranges={diastolicRange}
                            />
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div className="bmi-page__body__line-graph-text">
                              <Text size="micro">Heart Rate</Text>
                              <Text bold>{row.pulse} bpm</Text>
                              <Text size="nano" color="lightGrey">
                                Beats Per Min
                              </Text>
                            </div>
                            <HealthValueLine
                              key={index + row.id}
                              value={{
                                value: row.pulse,
                                text: row.status,
                              }}
                              ranges={heartRateRange}
                            />
                          </div>
                        </HealthValueLineWrapper>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {isMore && (
                <div className="bmi-page__body__list__load-more">
                  {dataToShow.length !== data.length ? (
                    <div className="bmi-page__body__list__load">
                      <Button
                        onClick={loadMore}
                        inverted
                        color="black"
                        size="fullWidth"
                        type="pill"
                      >
                        <Text
                          id="patient_app.health_wallet.vitals.body_mass_index.load_more"
                          bold
                        >
                          Load More
                        </Text>
                        <Icon name="angleDown" color="black" className="ml-2" />
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
            <div className="global__fixed-button">
              <Button
                className="mt-5"
                size="fullWidth"
                color="blue"
                onClick={createRecord}
              >
                <SIcon name="plus" color="white" />
                <Text
                  id="patient_app.health_wallet.vitals.add_record"
                  color="inherit"
                >
                  Add Record
                </Text>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(OxyPage);
