import { patientOverviewClient } from './patientOverviewClient';
// import patientOverviewClient from '../testAxios';
import { parsePhoneNumber } from 'react-phone-number-input';
import { createCache } from '../../utils/cacheUtils';

const vitalsCache = createCache(100, 60000 * 5) // 5 mins;
const riskModelsCache = createCache(100, 60000 * 5) // 5 mins;

const standardizePatientSignUp = (data = {}) => {
  const patient = { ...data };
  let emPhoneNumberObject = {
    countryCode: '',
    nationalNumber: '',
  };
  if (patient.emphoneNumber) {
    const emPhone = parsePhoneNumber(patient.emphoneNumber);
    emPhoneNumberObject = {
      countryCode: emPhone?.countryCallingCode || '',
      nationalNumber: emPhone?.nationalNumber || '',
    };
  }

  if (patient.phoneNumber) {
    const emPhone = parsePhoneNumber(patient.phoneNumber);
    patient.phoneNumber = {
      countryCode: emPhone?.countryCallingCode || '',
      nationalNumber: emPhone?.nationalNumber || '',
    };
  }
  patient.emergencyContact = {
    name: patient.fullName,
    title: patient.title,
    relationship: patient.relationship,
    phoneNumber: emPhoneNumberObject,
    language: patient.emlanguage,
  };

  patient.conditions = patient.preExisting;
  patient.familyHistories = patient.familyHistory;
  patient.allergies = patient.drugAllergy;
  patient.medications = patient.medication;

  delete patient.medication;
  delete patient.drugAllergy;
  delete patient.familyHistory;
  delete patient.preExisting;
  delete patient.fullName;
  delete patient.title;
  delete patient.relationship;
  delete patient.emlanguage;
  delete patient.emphoneNumber;
  return patient;
};

const standardizePatientUpdate = (data = {}) => {
  const patient = { ...data };
  if (patient.phoneNumber) {
    const phone = parsePhoneNumber(patient.phoneNumber);
    patient.phoneNumber = {
      countryCode: phone?.countryCallingCode || '',
      nationalNumber: phone?.nationalNumber || '',
    };
  } else {
    patient.phoneNumber = {
      countryCode: '',
      nationalNumber: '',
    };
  }
  return patient;
};

async function getLabResults() {
  try {
    const profileResponse = await patientOverviewClient.post('/getByUser', {});
    return profileResponse.data?.data?.profile?.labResults;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function addPulseOx(pulseOx) {
  const response = await patientOverviewClient.post('medicalProfile/create', {
    pulseOx,
  });

  return response.data;
}

async function signupPatient(data) {
  const patient = standardizePatientSignUp(data);
  const patientsResponse = await patientOverviewClient.post(
    `medicalProfile/signUp`,
    patient
  );
  return patientsResponse.data;
}

async function getSummaryOfData() {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/getSummaryOfData',
      {}
    );
    return profileResponse.data?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function updateProfilePatient(data) {
  const patient = standardizePatientUpdate(data);
  const patientsResponse = await patientOverviewClient.post(
    `medicalProfile/update`,
    patient
  );
  return patientsResponse.data;
}

async function getRiskAssessmentInformation() {
  const response = await patientOverviewClient.post(
    `medicalProfile/riskAssessment/getByUser`
  );
  return response.data;
}

async function updateAssessmentInformation(updateBody, patientId = '') {
  const response = await patientOverviewClient.post(
    `medicalProfile/riskAssessment/update${
      patientId ? `?id=${patientId}` : ''
    }`,
    updateBody
  );
  return response.data;
}

async function updateMedProfileQuestionnaire(patientId, updateBody) {
  const response = await patientOverviewClient.post(
    `medicalProfile/public/update/questionnaire`,
    { patientId, ...updateBody }
  );
  return response.data;
}

async function sendMedProfileQuestionnaire(updateBody) {
  const response = await patientOverviewClient.post(
    `/medicalProfile/send/questionnaire`,
    updateBody
  );
  return response.data;
}

async function getRiskModelData(modelName) {
  const response = await patientOverviewClient.post(
    `/medicalProfile/riskAssessment/${modelName}/getByUser`
  );
  return response.data;
}

const getAllRiskModelsDataWithCache = async (riskModelParams) => {
  const { id, shouldFetchOnlyPreviousStatus = false } = riskModelParams;
  const cacheKey = `risk-models-${id}-${shouldFetchOnlyPreviousStatus}`;

  const cachedData = riskModelsCache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  try {
    const response = await patientOverviewClient.post(
      `medicalProfile/riskAssessment/all/getByUser`,
      {
        shouldFetchOnlyPreviousStatus,
      },
      {
        params: {
          id,
        },
      }
    );

    if (response.data) {
      riskModelsCache.set(cacheKey, response.data);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching risk models data:', error);
    return null;
  }
};

async function getAllRiskModelsData(riskModelParams) {
  return getAllRiskModelsDataWithCache(riskModelParams);
}

async function getRiskAssessmentHistory(patientId = '') {
  const params = {};
  if (patientId) params.id = patientId;
  const response = await patientOverviewClient.get(
    `/medicalProfile/riskAssessmentHistory/getByUser`,
    { params }
  );
  return response.data;
}

const getAllVitalsWithCache = async (patientId = '') => {
  const cacheKey = `vitals-${patientId}`;

  const cachedData = vitalsCache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  try {
    const profileResponse = await patientOverviewClient.post(
      `medicalProfile/getByUser?id=${patientId}`,
      { fields: [] }
    );

    const visualAcuityList = profileResponse?.data?.data?.profile?.visualAcuityList;
    const res = {
      bmi: profileResponse.data?.data?.profile?.bmi,
      bloodPressure: profileResponse.data?.data?.profile?.bloodPressure,
      pulseOx: profileResponse.data?.data?.profile?.pulseOx,
      visualAcuity: visualAcuityList?.[visualAcuityList?.length - 1],
    };

    if (res) {
      vitalsCache.set(cacheKey, res);
    }

    return res;
  } catch (error) {
    console.error('Error fetching vitals data:', error);
    return null;
  }
};

async function getAllVitals(patientId = '') {
  return getAllVitalsWithCache(patientId);
}

async function getQuestionnaire(templateId) {
  return await patientOverviewClient.get(
    `medicalProfile/questionnaires/template/${templateId}`
  );
}

async function getPublicQuestionnaire(templateId, clientId) {
  return await patientOverviewClient.get(
    `medicalProfile/public/questionnaires/template/${templateId}`,
    {
      // headers: { 'x-client-id': clientId },
    }
  );
}

async function createPublicAnswers(body, clientId) {
  return await patientOverviewClient.post(
    'medicalProfile/public/questionnaires/answers',
    body,
    {
      // headers: { 'x-client-id': clientId },
    }
  );
}

async function createUserQuestionnaire(body) {
  const res = await patientOverviewClient.post(
    `medicalProfile/questionnaires/user-questionnaires`,
    body
  );
  return res.data;
}

async function createAnswers(body) {
  const res = await patientOverviewClient.post(
    `medicalProfile/questionnaires/answers/new`,
    body
  );
  return res.data;
}

async function updateUserQuestionnaire(body) {
  const res = await patientOverviewClient.put(
    `medicalProfile/questionnaires/user-questionnaires`,
    body
  );
  return res.data;
}

async function getUserQuestionnaires(params) {
  const res = await patientOverviewClient.get(
    'medicalProfile/questionnaires/user-questionnaires',
    { params }
  );
  return res.data;
}

async function getUserAnswers(params) {
  const res = await patientOverviewClient.get(
    'medicalProfile/questionnaires/answers',
    { params }
  );
  return res.data;
}
async function getPemeUserQuestionnaires(params) {
  const res = await patientOverviewClient.get(
    'medicalProfile/questionnaires/peme/user-questionnaires',
    { params }
  );
  return res.data;
}

async function updatePemeUserQuestionnaire(body) {
  const res = await patientOverviewClient.put(
    'medicalProfile/questionnaires/peme/user-questionnaires/bulk-update',
    body
  );
  return res.data;
}

async function deletePemeUserQuestionnaire(id) {
  const res = await patientOverviewClient.delete(
    `medicalProfile/questionnaires/peme/user-questionnaires/${id}`
  );
  return res.data;
}

async function issuePemeCertificate(userQuestionnaireId) {
  const res = await patientOverviewClient.post(
    `medicalProfile/questionnaires/peme/issue-certificate`,
    {
      userQuestionnaireId,
    }
  );
  return res.data;
}

async function downloadPemeCertificate(userQuestionnaireId) {
  const res = await patientOverviewClient.post(
    `medicalProfile/questionnaires/peme/download-url`,
    {
      userQuestionnaireId,
    }
  );
  return res.data;
}


const medicalProfileApi = {
  signupPatient,
  updateProfilePatient,
  updateMedProfileQuestionnaire,
  sendMedProfileQuestionnaire,
  getLabResults,
  addPulseOx,
  getSummaryOfData,
  getRiskAssessmentInformation,
  getRiskAssessmentHistory,
  updateAssessmentInformation,
  getRiskModelData,
  getAllRiskModelsData,
  getAllVitals,
  getQuestionnaire,
  getPublicQuestionnaire,
  createPublicAnswers,
  createUserQuestionnaire,
  createAnswers,
  getUserQuestionnaires,
  getUserAnswers,
  updateUserQuestionnaire,
  getPemeUserQuestionnaires,
  updatePemeUserQuestionnaire,
  deletePemeUserQuestionnaire,
  issuePemeCertificate,
  downloadPemeCertificate,
};

export default medicalProfileApi;
