import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { useCustomForm } from 'hooks';
import moment from 'moment';
import * as yup from 'yup';
import jwt from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

import {
  ErrorToast,
  Input,
  Select,
  Text,
  Cleave,
  Button,
  SuccessToast,
  Modal,
} from 'components';
import PatientLoginByGoogle from '../patientSignupV2/patientSignupByGoogle';

import IdAuthService from 'api/identity/authentication';
import IdUserService from 'api/identity/user';
import ClientService from 'api/client/client';

import { setUser, setClientInformation } from 'redux/generalState.actions';
import { createUserStorage, setClientStorage } from 'auth/authentication';

import logo from '../../../assets/new/patientApp/logo.svg';
import history from '../../../history';

const schema = yup.object().shape({
  email: yup.string().email().required('required').max(255),
  password: yup.string().required('required').max(255),
});

const PatientLogin = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [isModalToggled, setIsModalToggled] = useState('');
  const [isResetting, setIsResetting] = useState(false);

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    email: '',
    password: '',
  });

  useEffect(() => {
    localStorage.setItem('LOGIN_TYPE', 'patient');
  }, []);

  const onLogin = (data) => {
    onSubmit(data);
  };

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      await IdAuthService.onForgotPassword(resetEmail);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      setIsModalToggled(false);
      setResetEmail('');
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }

    setIsResetting(false);
  };

  const login = async ({ password, email }) => {
    setIsLoggingIn(true);
    setResetEmail(email);
    try {
      const authResp = await IdAuthService.onSignInUser({
        my_id: { password, username: email },
      });
      if (!authResp) {
        setIsLoggingIn(false);
        return toast.error(
          <ErrorToast message="Login failed, you have entered an invalid username or password." />
        );
      }

      const parsedLocalToken = jwt(authResp.data.access_token);
      const parsedRefreshToken = jwt(authResp.data.id_token);

      const localToken = {
        token: authResp.data.access_token,
        expires: parsedLocalToken.StandardClaims.exp,
        // expires: moment.utc().add(5, 'seconds'),
      };
      const refreshToken = {
        token: authResp.data.id_token,
        expires: parsedRefreshToken.StandardClaims.exp,
        // expires: moment.utc().add(10, 'seconds'),
      };
      localStorage.setItem('AUTH_TOKEN', JSON.stringify(localToken));
      localStorage.setItem('USER_ID', authResp.data.user_id);
      localStorage.setItem('REFRESH_TOKEN', JSON.stringify(refreshToken));
      const {
        data: { user },
      } = await IdUserService.getCurrentUser();
      if (user.metadata?.role === 'patient' && !user?.metadata?.fhirId) {
        const patient =
          await patientApi.createPatientByIdentityUserIfNotExist();
        if (patient?.fhirId) {
          const fhirId = patient.fhirId;
          user.metadata.fhirId = fhirId;
          await userApi.updateMetadata({ fhirId });
        }
      }
      dispatch(setUser(user));
      await createUserStorage(user);
      if (user?.metadata?.role === 'patient') return history.push('/p');
      // const {
      //   data: { client },
      // } = await ClientService.getCurrentClient();
      // dispatch(setClientInformation(client));
      // setClientStorage(client);
      // history.push('/results');
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoggingIn(false);
    }
  };

  const toggleForgotPassword = () => {
    setIsModalToggled(true);
  };

  return (
    <div className="signup">
      <div className="signup__logo">
        <img className="" alt="logo" src={logo} />
      </div>
      <div className="signup__left">
        <div className="signup__wraps signup__wraps__br-left">
          <h1>Better Health Starts Here</h1>
        </div>
      </div>
      <div className="signup__right">
        <div className="signup__wraps signup__wraps__br-right">
          <Text size="big" bold dark>
            Sign In
          </Text>
          {/* <p>Start your 30-day free trial.</p> */}
          <form onSubmit={handleSubmit(login)} className="signup__form mt-4">
            <div className="signup__form__group">
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="Email"
                    type="email"
                    errorMessage=""
                    placeholder="mail@domain.com"
                    size="fullWidth"
                    value={value}
                    invalid={errors?.email}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className="signup__form__group">
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="Password"
                    placeholder="**********"
                    size="fullWidth"
                    type="password"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    autoComplete="password"
                    invalid={errors?.password}
                    errorMessage=""
                  />
                )}
              />
            </div>
            <Button
              type="submit"
              size="fullWidth"
              className="signup__submit mt-4"
              isLoading={isLoggingIn}
            >
              Login
            </Button>
            <PatientLoginByGoogle type="login" />
            {/* <Button className="signup__google-signup">Sign up with Google</Button> */}
          </form>
          <Text size="mini" className="signup__login mt-4">
            Don't have an account?{' '}
            <a onClick={() => history.push('/p/create-account-v1')} href="#">
              Sign up
            </a>
          </Text>
          <div className="login__card__forgot-wrapper">
            <Button
              className="login__card__forgot"
              size="medium"
              onClick={() => toggleForgotPassword()}
              inverted
              color="white"
            >
              <span
                className="login__card__forgot__text"
                style={{
                  color: '#5076ff',
                }}
              >
                Forgot password?
              </span>
            </Button>
          </div>
        </div>
      </div>
      {isModalToggled && (
        <Modal
          closeIcon
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="You forgot your password? Enter your email below to receive a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              // color="blue"
              isLoading={isResetting}
              textId="forget_pw.reset_password"
            >
              Reset Password
            </Button>
          }
        >
          <Input
            border
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </div>
  );
};
export default PatientLogin;
