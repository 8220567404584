import React, { useEffect, useState } from 'react';
import { Text, Icon, Button } from 'components';
import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';

import { Modal } from 'semantic-ui-react';

import RiskAssessment from 'components/RiskAssessment/RiskAssessment';
import RiskAssessmentHistory from '../RiskAssessmentHistory';
import HealthValueLine from 'features/HealthValueLine/HealthValueLine';

import '../riskAssessment.scss';
import './riskAssessmentCompact.scss';

const ranges1 = [
  {
    text: 'Low',
    range: [0, 10],
    subText: '<10%',
    color: 'green',
  },
  {
    text: 'Intermediate',
    range: [11, 20],
    subText: '<20%',
    color: 'orange',
  },
  { text: 'High', range: [21, 100], subText: '', color: 'red' },
];

const RiskAssessmentCompact = ({
  modelName,
  data: iData,
  getDataHistoryByModelName,
  icon,
  hideToggle,
  showHistoryToggle,
  small,
}) => {
  const [data, setData] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const [historyIsToggled, setHistoryIsToggled] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [modelLabel, setModelLabel] = useState(null);
  const [ranges, setRanges] = useState(ranges1);

  useEffect(() => {
    const formattedData = { ...iData };
    formattedData.score = Math.round(iData.score * 1000) / 10;
    setData(formattedData);
    formatRanges(formattedData.reference_range);
  }, [iData]);

  useEffect(() => {
    if(!getDataHistoryByModelName || !showHistoryToggle) return
    const modelLabel = getLabel();
    const dataHistory = getDataHistoryByModelName
      ? getDataHistoryByModelName(modelLabel)
      : [];
    setModelLabel(modelLabel);
    if (dataHistory && dataHistory?.length > 0) setDataHistory(dataHistory);
  }, []);

  const formatRanges = (ranges) => {
    const rangez = Object.keys(ranges).map((key, index) => {
      const color = getColor(key.toLowerCase()).color;
      const values = [
        Math.round(ranges[key][0] * 10000) / 100,
        Math.round(ranges[key][1] * 10000) / 100,
      ];

      return {
        text: key,
        range: values,
        subText:
          index === Object.keys(ranges).length - 1
            ? values[0] + ' - ' + values[1] + '%'
            : '<' + values[1] + '%',
        color,
      };
    });

    setRanges(rangez);
  };

  const getLabel = () => {
    switch (modelName) {
      case 'cardiovascular':
        return 'Cardiovascular Disease';
      case 'hypertension':
        return 'Hypertension';
      case 'coronaryHeartDisease':
        return 'Coronary Heart Disease';
      case 'liver':
        return 'Non-Alcoholic Fatty Liver';
      case 'diabetes':
        return 'Diabetes';
      case 'kidney_stage3':
        return 'Chronic Kidney Disease Stage 3';
      case 'kidney_stage5':
        return 'Chronic Kidney Disease Stage 5';
      case 'goutDisease':
        return 'Gout Disease';
      default:
        return '';
    }
  };

  const getIcon = () => {
    const lCase = data?.risk_score_status?.toLowerCase();
    if (lCase === 'low' || lCase === 'normal') return NormalIcon;

    return HighIcon;
  };

  // Yo Alfred, check this out
  const getColor = (status) => {
    if (status === 'low' || status === 'normal')
      return { color: 'green', colorCode: '#00c48c' };
    if (status === 'borderline')
      return { color: 'yellow', colorCode: '#FFD443' };
    if (status === 'intermediate')
      return { color: 'orange', colorCode: '#ffaf3e' };
    return { color: 'red', colorCode: '#ff6666' };
  };

  return (
    <div className="rac">
      {isToggled && (
        <Modal
          className="modal-full rac__modal"
          open={isToggled}
          onClose={() => setIsToggled(false)}
          closeIcon
          size="large"
        >
          <RiskAssessment
            modelName={modelName}
            clientView
            data={iData}
            icon={icon}
          />
        </Modal>
      )}
      {historyIsToggled && showHistoryToggle && (
        <Modal
          style={{ width: '500px', padding: '20px' }}
          open={historyIsToggled}
          onClose={() => setHistoryIsToggled(false)}
          closeIcon
          size="large"
        >
          <div className="risk-model__header">
            <img className="risk-model__header__icon" alt="ic" src={icon} />
            <div className="risk-model__header__text">
              <Text dark size="big" className="mb-1">
                {modelLabel}
              </Text>
              <Text size="tiny" bold>
                {data.period} risk forecast based on {data.age_group} age group
              </Text>
            </div>
          </div>
          <RiskAssessmentHistory
            data={iData}
            clientView
            dataHistory={dataHistory}
            label={modelLabel}
          />
        </Modal>
      )}
      <div className="risk-model__header">
        <img
          className="rac__icon"
          alt="ic"
          src={icon}
          style={{ width: small ? '55px' : '70px' }}
        />
        <div className="risk-model__header__text">
          <div className="rac__tag-wrapper">
            <Text dark size={small ? 'small' : 'big'}>
              {getLabel()}
            </Text>
            <Text
              color="white"
              size={small ? 'tinniest' : 'tiny'}
              bold
              className={`rac__tag rac__tag--${data?.risk_score_status?.toLowerCase()}`}
            >
              <img
                className="rac__tag__icon"
                src={getIcon()}
                alt="icon"
                style={{
                  width: small ? '15px' : '20px',
                  height: small ? '15px' : '20px',
                }}
              />
              {data.risk_score_status}
            </Text>
          </div>
          <Text
            size="tiny"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'flex',
            }}
          >
            Your calculated risk score is{' '}
            <span
              className={`rac__status rac__status--${data?.risk_score_status?.toLowerCase()}`}
            >
              {data?.score}%
            </span>
          </Text>
          <div className="rac__graph">
            <HealthValueLine
              className="rac__graph__line"
              value={{
                value: data?.score,
                text: 'normal',
                unit: '%',
              }}
              color={getColor(data?.risk_score_status?.toLowerCase())}
              showValue={false}
              ranges={ranges}
            />
          </div>
          {showHistoryToggle && (
            <Button
              style={{ height: '30px' }}
              type="pill"
              size="fluid"
              sty
              color="blue"
              inverted
              disabled={dataHistory?.length < 1}
              onClick={() => setHistoryIsToggled(true)}
            >
              <Text size="tiny" color="blue">
                View assessment history
              </Text>
            </Button>
          )}
        </div>
        {!hideToggle && (
          <div className="rac__button">
            <div
              className="rac__dropdown-toggle"
              onClick={() => setIsToggled(true)}
            >
              <Icon name="angleRight" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskAssessmentCompact;
