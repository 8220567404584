import axios from 'axios';
import { uploadModes } from '../constants';
import { createAxiosInstance } from '../api/axiosInstance';
import store from '../store';

window.Buffer = window.Buffer || require("buffer").Buffer;

const axiosInstance = createAxiosInstance(`${process.env.REACT_APP_API_V1_FHIR_END_POINT}/s3/`);

// random comment
export const s3UploadMixin = {
  data: () => ({
    fileName: '',
    folder: null,
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  config() {
    return {
      bucketName: 'riverr-trustroot',
      dirName: this.folder,
      region: 'ap-southeast-1',
      s3Url: 'https://riverr-trustroot.s3-accelerate.amazonaws.com',
    };
  },
  async getUploadUrl(fileName, fileType) {
    const response = await axiosInstance.post('/upload-url', {
      bucketName: this.config().bucketName,
      fileName,
      fileType,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data.uploadUrl;
  },
  newFileName() {
    return Math.random().toString().slice(2);
  },
  url() {
    return `${this.config().s3Url}/${this.folder}/${this.newFileName()}`;
  },
  removeFile(type) {
    const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
    // this.$emit(`remove${typeCapitalized}`);
  },
  onFileChange(files, type) {
    this.uploadFileToS3(type, files);
  },
  async uploadFileToS3(type = 'image', files, id) {
    const clientId = id || store?.getState()?.general?.user?.currentClientId;
    const file = files;
    let folder = null;

    switch (type) {
      case uploadModes.certificateLogo:
        folder = `${clientId}/logos`;
        break;
      case uploadModes.certificateInstructorSignature:
        folder = `${clientId}/instructorSignatures`;
        break;
      case uploadModes.certificateIssuerSignature:
        folder = `${clientId}/issuerSignatures`;
        break;
      case uploadModes.certificateWatermark:
        folder = `${clientId}/watermark`;
        break;
      case uploadModes.certAttachments:
        folder = `${clientId}/certAttachments`;
        break;
      case uploadModes.newAppointmentClientLogo:
        folder = `${clientId}/newAppointmentClientLogo`;
        break;
      case uploadModes.patientProfileImage:
        folder = `${clientId}/patientProfileImage`;
        break;
      default:
        folder = `${clientId}/images`;
    }
    this.folder = folder;

    const fileName = `${folder}/${this.newFileName()}`;
    const uploadUrl = await this.getUploadUrl(fileName, file.type);
    
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return {
      location: `${this.config().s3Url}/${fileName}`
    };
  },
};
