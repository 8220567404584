import axios from 'axios';
import moment from 'moment';

import { API_V1_FHIR_END_POINT } from '../constants';
import { signOutUser, refreshUserToken } from 'auth/authentication';
import {
  setRefreshTokenCount,
} from 'redux/generalState.actions';

import store from '../store';

function createAxiosInstance(endpoint, options = {}) {
  const axiosInstance = axios.create({
    baseURL: endpoint,
  });

  if (options.timeout) {
    axiosInstance.defaults.timeout = options.timeout;
  }

  const requestHandler = (request) => {
    if (
      localStorage.getItem('AUTH_TOKEN') &&
      JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
    ) {
      request.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
      }`;
    }
    const clientId = store?.getState()?.general?.user?.currentClientId;
    // request.headers['x-client-id'] =
    //   request.headers['x-client-id'] || clientId || '00000000000000000000'; // default client id for patient app
    return request;
  };

  const refreshAndRetryQueue = [];
  let isRefreshing = false;

  const errorHandler = async (error) => {
    const originalRequest = error.config;
    const localRefreshToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
    if (
      (!localRefreshToken ||
        (localRefreshToken &&
          moment.utc(localRefreshToken.expires).isBefore(moment()))) &&
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      error.response.config.url.substring(0, 5) !== '/auth'
    ) {
      return signOutUser(true);
    }

    if (
      localRefreshToken &&
      localRefreshToken &&
      moment.utc(localRefreshToken.expires).isAfter(moment()) &&
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      error.response.config.url.substring(0, 5) !== '/auth'
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshCount = store?.getState()?.general?.refreshCount
        if(refreshCount >= 3) return store.dispatch(setRefreshTokenCount(0))
        store.dispatch(setRefreshTokenCount(refreshCount + 1))
        try {
          const tokens = await refreshUserToken();
          const newAccessToken = tokens.accessToken;
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

          //retry failed requests in queue
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInstance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          //clear queue
          refreshAndRetryQueue.length = 0;

          //retry original request
          return axiosInstance(originalRequest);
        } catch (error) {
          throw error;
        } finally {
          isRefreshing = false;
        }
      }

      //add the original request to the queue if 'isRefreshing' is true
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error)
  );

  return axiosInstance;
}

const axiosInstance = createAxiosInstance(API_V1_FHIR_END_POINT);

export default axiosInstance;

export { createAxiosInstance };
