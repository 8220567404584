import React, { useState } from 'react';
import './customMenu.scss';

const CustomMenu = ({ menuItems, onItemClick }) => {
  const [activeItem, setActiveItem] = useState(menuItems[0]);

  const handleItemClick = (item) => {
    setActiveItem(item);
    onItemClick(item);
  };

  return (
    <div className="custom-menu">
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`menu-item ${activeItem === item ? 'active' : ''}`}
          onClick={() => handleItemClick(item)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default CustomMenu;
