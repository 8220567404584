import React, { useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { SuccessToast, ErrorToast, Text } from 'components';

import { checkIfNativePdf, getFileSize } from 'helpers/pdf';
import { checkIfImage } from 'helpers/image';

import resultApi from 'api/result/resultApi';
import extractorApi from 'api/extractorApi/extractorApi';
import fhirResultApi from 'api/fhir/fhirResult/fhirResultApi';
import patientApi from 'api/patient/patientApi';
import patientReactSessionApi from 'api/patientReactSession';
import time from '../../utils/time';
import ResultsPdfUploadModalContent from './ResultPdfUploadModalContent';

import './resultPdfUploadModal.scss';

const reportTypeOptions = {
  diagnostic: [
    { text: 'CT-scan', value: 'CT-scan' },
    { text: 'ECG', value: 'ECG' },
    { text: 'Imaging', value: 'Imaging' },
    { text: 'MRI', value: 'MRI' },
    { text: 'Ultrasound', value: 'Ultrasound' },
    { text: 'X-Ray', value: 'X-Ray' },
  ],
  pulmonary: [
    {
      text: 'Cardiopulmonary exercise testing (CPET)',
      value: 'Cardiopulmonary exercise testing (CPET)',
    },
    { text: 'Spirometry', value: 'Spirometry' },
  ],
  audio: [
    { text: 'Audio Acuity Test', value: 'Audio Acuity Test' },
    { text: 'Visual Acuity Test', value: 'Visual Acuity Test' },
  ],
};

const ResultsPdfUploadModal = ({
  isModalToggled,
  toggleModal,
  patientDetails,
  refreshParent,
  patientReactSessionId,
  isPatientApp,
  selectedTab,
  fetchResults,
}) => {
  const { clientInformation, user } = useSelector((state) => state.general);
  const canvasRef = useRef(null);

  useEffect(() => {
    setUploadStep(1);
    setIsNative(true);
    setFileSize(0);
  }, [isModalToggled]);

  // const doesExist = async () => {
  //   const res = await resultApi.getPatientResults({
  //     patientId: patientDetails.fhirId,
  //     xClientId: clientInformation?.clientId,
  //   });
  //   console.log(res);
  // };

  const isShare = window.location.pathname.includes('shared');
  const clientId =
    isPatientApp || isShare
      ? '00000000000000000000'
      : clientInformation?.clientId || '';
  const clientName =
    isPatientApp || isShare ? 'Patient App' : clientInformation?.name;

  const [isSaving, setIsSaving] = useState(false);
  const [type, setType] = useState(selectedTab || 'result');
  const [uploadedPdf, setUploadedPdf] = useState(null);
  const [pdfPasword, setPdfPassword] = useState('');
  const [isAbnormal, setIsAbnormal] = useState(false);
  const [reportFileType, setReportFileType] = useState('');
  const [doctorNote, setDoctorNote] = useState('');
  const [uploadStep, setUploadStep] = useState(1);
  // Not sure if base64 is needed - remove if not, along with onLoadSuccessHandler
  const [base64File, setBase64File] = useState(null);
  const [isPdfNative, setIsNative] = useState(true);
  const [fileSize, setFileSize] = useState(0);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    if (fileSize > 8000)
      toast.info(
        <SuccessToast message="The selected file size should not exceed 8MB" />
      );
  }, [fileSize]);

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const fetchPatientReactSession = async (id) => {
    try {
      const pSession = await patientReactSessionApi.getPatientReactSessionById(
        id
      );
      return pSession?.data;
    } catch (error) {
      toast.error(<ErrorToast error={error} />, { toastId: 'fetch-err' });
      return null;
    }
  };

  const uploadNonExtractedPdf = async (
    dataOverWrite = {},
    isExtractionAttempt = false
  ) => {
    setIsSaving(true);
    try {
      const pdf = [
        {
          name: uploadedPdf.name,
          file: await convertFileToBase64(uploadedPdf),
          password: pdfPasword,
          type: reportFileType,
        },
      ];

      let fhirId = patientDetails?.fhirId || patientDetails?.metadata?.fhirId;
      if (!fhirId) {
        const patient = await patientApi.createFhirPatientIfNotExist(
          patientDetails?.id
        );
        fhirId = patient.fhirId;
      }

      const resultData = {
        clients: [clientId],
        lab: {
          clientId,
        },
        clinic: {
          clientId,
        },
        version: isImage ? 'image' : 'PDF',
        status: 'final',
        display: '',
        doctorNote,
        type,
        reportedDate: moment().toISOString(),
        uploadedBy: user?.safe_id,
        patient: {
          id: String(patientDetails?.id) || '',
          fhirId,
          ssn: patientDetails?.ssn || patientDetails?.metadata?.ssn || '',
          passNo:
            patientDetails?.passNo || patientDetails?.metadata?.passNo || '',
          fullName:
            patientDetails?.fullName || patientDetails?.metadata?.name || '',
          gender:
            patientDetails?.gender || patientDetails?.metadata?.gender || '',
          email: patientDetails?.email || patientDetails?.metadata?.email || '',
          externalId: '',
          dateOfBirth:
            (!!patientDetails?.dateOfBirth &&
              moment(patientDetails?.dateOfBirth).format('YYYY-MM-DD')) ||
            (!!patientDetails?.metadata?.dateOfBirth &&
              moment(patientDetails?.metadata?.dateOfBirth).format(
                'YYYY-MM-DD'
              )) ||
            '',
        },
        anomalyStatus: isAbnormal ? 'abnormal' : 'normal',
        pdf,
        ...dataOverWrite,
      };
      const res = await resultApi.createResultDirectly(resultData);
      if (res) {
        if (patientReactSessionId) {
          const patientSession = await fetchPatientReactSession(
            patientReactSessionId
          );
          const resultId = clientId + '-' + res?.id;
          const newResultId = { id: resultId, accNo: res?.id };
          const payload = {
            resultIds: [...patientSession?.resultIds, newResultId],
          };
          await patientReactSessionApi.updatePatientReactSession(
            patientSession?._id,
            payload
          );
        }
      }
      fetchResults && (await fetchResults({}));
      toggleModal(false);
      setUploadedPdf(null);
      const successMessage = isExtractionAttempt
        ? 'Report uploaded, no data was extracted.'
        : 'Report uploaded.';
      toast.dismiss();
      toast.success(<SuccessToast message={successMessage} />);
    } catch (error) {
      toast.error(<ErrorToast message="Report upload failed." />);
    } finally {
      setIsSaving(false);
    }
  };

  const checkIfPdfIsNativeAndSize = async (file) => {
    let isNative = false;

    if (checkIfImage(file)) {
      setIsImage(true);
    } else {
      isNative = await checkIfNativePdf(file);
      setIsNative(true);
    }
    const fileSize = getFileSize(file);
    // setIsNative(isNative);
    setFileSize(fileSize);

    return { isNative, fileSize, isImage };
  };

  const uploadPdf = async () => {
    try {
      if (!uploadedPdf) return;
      if (type !== 'result') return await uploadNonExtractedPdf();

      setIsSaving(true);
      // TODO: API call
      // TODO: Save password as we need it to open the PDF in the BE for content extraction
      // pdfPasword - We can also save the password if needed
      const patName = patientDetails?.fullName?.split(' ') || '';
      const patFirstName = patName?.[0] || patientDetails?.metadata?.firstName;
      let patLastName = patName?.[1] || patientDetails?.metadata?.lastName;
      if (patName.length > 2) {
        patLastName = patName.splice(1, patName.length - 1).join(' ');
      }

      let fhirId = patientDetails?.fhirId || patientDetails?.metadata?.fhirId;
      if (!fhirId) {
        const patient = await patientApi.createFhirPatientIfNotExist(
          patientDetails?.id
        );
        fhirId = patient.fhirId;
      }

      const data = {
        fhirId,
        ssn: patientDetails?.ssn || patientDetails?.metadata?.ssn || null,
        firstName: patientDetails?.givenName || patFirstName,
        lastName: patientDetails?.familyName || patLastName,
        clinicName: clientName || null,
        clinicId: clientId,
        birthDateYmd:
          (!!patientDetails?.dateOfBirth &&
            moment(patientDetails?.dateOfBirth).format('YYYY-MM-DD')) ||
          (!!patientDetails?.metadata?.dateOfBirth &&
            moment(patientDetails?.metadata?.dateOfBirth).format(
              'YYYY-MM-DD'
            )) ||
          null,
        gender:
          patientDetails?.gender || patientDetails?.metadata?.gender || null,
        contactNumber:
          patientDetails?.phoneNumber || patientDetails?.phone_number || null,
        address:
          patientDetails?.address || patientDetails?.metadata?.address || null,
        nationality:
          patientDetails?.nationality ||
          patientDetails?.metadata?.nationality?.text ||
          null,
        passNo:
          patientDetails?.passNo || patientDetails?.metadata?.passNo || null,
        ethnicity:
          patientDetails?.ethnicity?.text ||
          patientDetails?.metadata?.ethnicity?.text ||
          null,
      };

      const formData = new FormData();
      data.ssn && formData.append('ssn', data.ssn);
      data.fhirId && formData.append('fhirId', data.fhirId);
      data.clinicId && formData.append('clinicId', data.clinicId);
      data.firstName && formData.append('firstName', data.firstName);
      data.lastName && formData.append('lastName', data.lastName);
      data.clinicName && formData.append('clinicName', data.clinicName);
      data.birthDateYmd && formData.append('birthDateYmd', data.birthDateYmd);
      data.gender && formData.append('gender', data.gender);
      data.contactNumber &&
        formData.append('contactNumber', data.contactNumber);
      data.address && formData.append('address', data.address);
      data.nationality && formData.append('nationality', data.nationality);
      data.passNo && formData.append('passNo', data.passNo);
      data.ethnicity && formData.append('ethnicity', data.ethnicity);
      formData.append('files', uploadedPdf);

      const extractionToastId = 'waiting-extraction-toast';
      toast.info(
        <SuccessToast message="The upload may take a couple minutes, we will notify when the upload is complete." />,
        { autoClose: false, toastId: extractionToastId }
      );

      toggleModal(false);
      let bundle;
      try {
        const pdfExtractorResponse = await extractorApi.uploadMultiPdfV2(
          formData
        );
        bundle = pdfExtractorResponse?.bundle;
        // upload as non extracted pdf if extraction fails

        console.log('extract response-', pdfExtractorResponse);
      } catch (error) {
        console.log(error);
        // return await uploadNonExtractedPdf({ type }, true);
      }

      const bundleId = bundle.split(' ')[1];
      const resultId = clientId + '-' + bundleId;

      const pdf = [
        {
          name: uploadedPdf.name,
          file: await convertFileToBase64(uploadedPdf),
          password: pdfPasword,
        },
      ];

      const resultData = {
        resultId,
        pdf,
        userId: user?.safe_id,
      };

      await time.sleep(5000);
      // check for bundle is created or not for 8 times with 1 sec interval and then send the pdf
      for (let i = 0; i < 20; i++) {
        const { results } = await resultApi.getPatientResults({
          patientId: patientDetails.fhirId,
          xClientId: clientInformation?.clientId,
        });
        if (results && results?.length) {
          const lastCreatedObject = results.reduce((latest, obj) => {
            return moment(obj.createdAt).isAfter(moment(latest.createdAt))
              ? obj
              : latest;
          });

          const isWithin20Minutes =
            moment().diff(moment(lastCreatedObject.createdAt), 'minutes') < 5;

          //  const doesExist = await resultApi.checkResultExists(resultId);
          if (isWithin20Minutes) {
            await resultApi.sendResultsFile({
              ...resultData,
              resultId: lastCreatedObject.fhirId,
            });
            toast.dismiss(extractionToastId);
            break;
          }
        }
        await time.sleep(3000);
      }
      await time.sleep(3000);
      fetchResults && (await fetchResults({}));
      toggleModal(false);
      setUploadedPdf(null);
      toast.success(<SuccessToast message="Report uploaded." />);
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false);
    }
  };

  const fileSelectHandler = useCallback(async (e, setFile) => {
    e.preventDefault();
    let reader = new FileReader();
    let files = e.target.files;

    if (!files.length) return;

    setIsSaving(true);
    let file = null;
    if (files.length === 1 && files[0].type === 'application/pdf') {
      // Single PDF file upload
      file = files[0];
    } else {
      // Multiple image files upload
      const imageFilesArray = Array.from(files).filter((file) =>
        file.type.startsWith('image/')
      );
      file = await mergeImages(imageFilesArray);
    }

    setFile(file);

    reader.readAsDataURL(file);

    checkIfPdfIsNativeAndSize(file);
    setIsSaving(false);
  }, []);

  const mergeImages = async (files) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let maxWidth = 0;
    let totalHeight = 0;

    // Load and calculate dimensions for each selected image
    for (let i = 0; i < files.length; i++) {
      const image = new Image();
      const path = URL.createObjectURL(files[i]);
      image.src = path;

      // Wait for the image to load
      await image.decode();

      maxWidth = Math.max(maxWidth, image.width);
      totalHeight += image.height;
    }

    // Set the canvas size
    canvas.width = maxWidth;
    canvas.height = totalHeight;

    // Draw each selected image onto the canvas
    let currentY = 0;

    for (let i = 0; i < files.length; i++) {
      const image = new Image();
      const path = URL.createObjectURL(files[i]);
      image.src = path;

      // Wait for the image to load
      await image.decode();

      context.drawImage(image, 0, currentY, maxWidth, image.height);

      currentY += image.height;
    }

    return new Promise((resolve) => {
      canvas.toBlob(
        async (blob) => {
          const mergedImageFile = new File([blob], `merged_image.jpg`, {
            type: `image/jpeg`,
          });
          resolve(mergedImageFile);
        },
        'image/jpeg',
        0.7
      );
    });
  };

  function callbackProxy(callback, password) {
    // Cancel button handler
    if (password === null) {
      setUploadedPdf(null);
      setPdfPassword(null);
      return;
    }

    setPdfPassword(password);
    callback(password);
  }

  const onPasswordHandler = (callback, reason) => {
    switch (reason) {
      case 1: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(callback, password);
        break;
      }
      case 2: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(callback, password);
        break;
      }
      default:
    }
  };

  const onNextUploadStep = (step) => {
    if (step === 2 && !uploadedPdf) return;
    setUploadStep(step);
  };

  const onLoadSuccessHandler = async (pdf) => {
    // This is used to extract password protected PDFs base64 data
    // Non password protected PDFs also go through this flow
    const pdfData = await pdf.getData();
    const pdfBase64 = Buffer.from(pdfData).toString('base64');
    setBase64File(pdfBase64);
  };

  return (
    <Modal
      className="modal-full r-pdf-um"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header style={{ paddingBottom: '10px' }}>
        <Text id="react_report.upload_report" size="big" bold>
          Upload Report
        </Text>
      </Modal.Header>
      <Modal.Content>
        <ResultsPdfUploadModalContent
          reportTypeOptions={reportTypeOptions}
          isAbnormal={isAbnormal}
          setIsAbnormal={setIsAbnormal}
          uploadedPdf={uploadedPdf}
          setUploadedPdf={setUploadedPdf}
          type={type}
          setType={setType}
          doctorNote={doctorNote}
          setDoctorNote={setDoctorNote}
          reportFileType={reportFileType}
          setReportFileType={setReportFileType}
          uploadStep={uploadStep}
          isSaving={isSaving}
          onNextUploadStep={onNextUploadStep}
          uploadPdf={uploadPdf}
          onPasswordHandler={onPasswordHandler}
          onLoadSuccessHandler={onLoadSuccessHandler}
          fileSelectHandler={fileSelectHandler}
          isPatientApp={isPatientApp}
          isPdfNative={isPdfNative}
          fileSize={fileSize}
          isImage={isImage}
        />
        <canvas style={{ display: 'none' }} ref={canvasRef}></canvas>
      </Modal.Content>
    </Modal>
  );
};

export default ResultsPdfUploadModal;
