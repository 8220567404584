const createCache = (maxSize = 100, maxAge = 5000) => {
  const cache = new Map();

  return {
    get(key) {
      const item = cache.get(key);
      if (!item) return null;

      if (Date.now() - item.timestamp > maxAge) {
        cache.delete(key);
        return null;
      }

      return item.value;
    },

    set(key, value) {
      // Remove oldest entry if cache is full
      if (cache.size >= maxSize) {
        const oldestKey = cache.keys().next().value;
        cache.delete(oldestKey);
      }

      cache.set(key, {
        value,
        timestamp: Date.now()
      });
    },

    clear() {
      cache.clear();
    }
  };
};


module.exports = {
  createCache
};
