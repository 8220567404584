import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pdfjs } from 'react-pdf';

import { Button, ErrorToast, Text, PDFViewer, ImageViewer } from 'components';
import pdfnetApi from 'api/pdfnetApi/pdfnetApi';

import './labReportPdfPreview.scss';
import resultApi from 'api/result/resultApi';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const LabReportPdfPreview = ({ reportId, pdf = [], patient }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [showGenerate, setShowGenerate] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [isImage, setImage] = useState(false);

  const userInfo = useSelector((state) => state.general.user);

  const genLabReportPdf = async () => {
    setGeneratingPdf(true);
    const clientId = userInfo.currentClientId;
    try {
      const { data } = await pdfnetApi.genLabReportPdf(reportId, clientId);
      setPdfUrl(data.downloadUrl);
      setShowGenerate(false);
    } catch (error) {
      console.log('error', error);
      toast.error(
        <ErrorToast message="Error generating PDF, please try again later" />
      );
    } finally {
      setGeneratingPdf(false);
    }
  };

  const getSignedPdfUrl = async () => {
    try {
      const pdfPath = pdf?.at(-1)?.file || '';
      if (!pdfPath) return setShowGenerate(true);
      const isImageFile = /\.(jpg|jpeg|png|bmp|svg)$/i.test(pdf[0].name);
      setImage(isImageFile);
      const signedFiles = await resultApi.getBulkSignedFile(pdfPath);
      // const { data } = await pdfnetApi.getPdfDownloadUrl(pdfPath);
      setPdfUrl(signedFiles[0]);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getSignedPdfUrl();
  }, []);

  return (
    <div className="lab-report-pdf">
      {showGenerate ? (
        <div className="lab-report-pdf__generate-pdf">
          <Text bold>The Lab Result doesn't have a PDF Lab Report</Text>
          <Button
            color="blue"
            size="large"
            className="mt-3"
            inverted
            isLoading={generatingPdf}
            onClick={() => genLabReportPdf()}
          >
            Generate PDF Lab Report
          </Button>
        </div>
      ) : (
        <>
          {isImage && <ImageViewer imgUrl={pdfUrl} />}
          {pdfUrl && !isImage && <PDFViewer url={pdfUrl} />}
        </>
      )}
    </div>
  );
};

export default LabReportPdfPreview;
