import { patientOverviewClient } from './patientOverviewClient';

async function getBloodPressuresOfPatient() {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/getByUser',
      { fields: ['bloodPressureList'] }
    );
    return profileResponse.data?.data?.profile?.bloodPressureList || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function addBloodPressure(bloodPressure) {
  const response = await patientOverviewClient.post('medicalProfile/create', {
    bloodPressure,
  });

  return response.data;
}

async function updateBloodPressure(bloodPressure) {
  const response = await patientOverviewClient.post(
    'medicalProfile/bloodPressures/update',
    bloodPressure
  );
  return response.data;
}

async function deleteBloodPressure(id) {
  const response = await patientOverviewClient.post(
    'medicalProfile/bloodPressures/delete',
    {
      id,
    }
  );
  return response.data;
}

const bloodPressureApi = {
  getBloodPressuresOfPatient,
  addBloodPressure,
  updateBloodPressure,
  deleteBloodPressure,
};

export default bloodPressureApi;
