import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { SuccessToast } from 'components';

import Login from '../pages/login';
import LoaderPage from '../pages/loaderPage';
import PublicPatientForm from '../pages/patientForm/PublicPatientForm';
import PatientSignupWrapper from '../pages/patientPages/patientSignupWrapper/PatientSignupWrapper';
import PatientSignupV1Wrapper from 'pages/patientPages/patientSignupV1Wrapper/PatientSignupV1Wrapper';
import PatientSuccessSignup from '../pages/patientPages/patientSignup/components/SuccessPage';

import CreateAccountSuccess from '../pages/accountPages/createAccountSuccess/CreateAccountSuccess';

import PublicCertificateView from '../pages/certificatePages/publicCertificateView/PublicCertificateView';
import PublicQRView from '../pages/certificatePages/publicQrCertView/PublicQrCertView';
import PlatoIssue from '../pages/certificatePages/issueCertificate/PlatoIssue';
import TinyUrlView from '../pages/certificatePages/publicCertificateView/TinyUrlView';

import PublicReactHealthQuestions from '../pages/patientPages/ReactHealthQuestions/PublicReactHealthQuestions';
import ReactFormSuccess from '../pages/patientPages/ReactHealthQuestions/components/formSuccessPage/FormSuccessPage';
import PublicQuestionnaire from 'pages/certificatePages/issueCertificate/questionnaire/publicQuestionnaire';
import PemeSuccessPage from 'pages/certificatePages/issueCertificate/questionnaire/components/successPage';

import IntegrationPatientDashboard from '../pages/integrationPages/PatientDashboard/PatientDashboard';

import ReactHealthContainerExternal from 'pages/reactHealthPages/ReachHealthOverview/ReactHeathContainerExternal';
import ReactHealthDetails from 'pages/reactHealthPages/ReactHealthDetails/ReactHealthDetails';
import PatientReactSessionDetails from 'pages/reactHealthPages/ReactHealthDetails/components/PatientReactSessionDetails/PatientReactSessionDetails';
import PatientsFhir from '../pages/patientsFhir/PatientsFhir';

import ConsumablesRequest from 'pages/consumablesRequest';

import { signOutUser, refreshUserToken } from 'auth/authentication';

import idUserService from 'api/identity/user';

import elabFavicon from 'assets/logo-icons/elabFavicon.ico';

import { setUser, setCustomUrlClientId } from 'redux/generalState.actions';
import { usePusher } from '../hooks/usePusher';

import history from '../history';
import PatientSignupV2 from 'pages/patientPages/patientSignupV2/patientSignupV2';
import PatientLogin from 'pages/patientPages/patientLogin/PatientLogin';

const ClientRoutes = lazy(() => import('./ClientRoutes'));
const PatientRoutes = lazy(() => import('./PatientRoutes'));

const PATIENT_DOMAINS = [
  'riverr.health',
  'dev.riverr.health',
  'www.riverr.health',
];

export const MainRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.general.user);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isLoading, setIsLoading] = useState(true);

  const [isPatientLogin, setPatientLogin] = useState(false);

  // Todo add a check to see if user is authenticated
  useEffect(() => {
    getInitialData();
  }, []); //eslint-disable-line

  useEffect(() => {
    const domain = window.location.hostname;
    if (PATIENT_DOMAINS.includes(domain)) setPatientLogin(true);
    // if (domain === 'localhost') setPatientLogin(true);
  }, []);

  useEffect(() => {
    window.Intercom('update');
  }, [location]);

  useEffect(() => {
    if (isMobile) return;
    window.Intercom('boot', {
      app_id: 'a1z5uy25',
      name: userInfo?.name,
      email: userInfo?.email,
      user_id: userInfo?.id,
      alignment: 'right',
      horizontal_padding: 0,
      vertical_padding: 0,
    });
  }, [userInfo]);

  const getInitialData = async () => {
    await checkAppUrl();
    setIsLoading(false);
  };

  const checkAppUrl = async () => {
    // get base url
    const baseUrl = window.location.origin;
    if (
      baseUrl === 'https://elab.eurofins.sg' ||
      baseUrl === 'https://dev-ef.app.riverr.ai'
    ) {
      // if (baseUrl === 'http://localhost:3000') {
      setCustomClientData();
    } else localStorage.setItem('CUSTOM_DOMAIN', 'RIVERR');
    await checkIfAuthenticated();
  };

  const setCustomClientData = () => {
    document.title = 'eLab Portal';
    const favicon = document.querySelector("link[rel~='icon']");
    if (favicon) favicon.href = elabFavicon;
    localStorage.setItem('CUSTOM_DOMAIN', 'EUROFINS');
    dispatch(setCustomUrlClientId('sIdzwYsvwehphIHNG2ce'));
  };

  const checkIfAuthenticated = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      if (params.get('external')) return;
      const authToken = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
      const refreshToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
      const path = history.location.pathname;
      if (!authToken) {
        if (
          path === '/activation/get' ||
          // TODO: get rid of this, skips auth for patient pages
          path === '/reset-password/get' ||
          path.includes('/patient-form') ||
          path.includes('/p/create-account') ||
          path.includes('/public/document') ||
          path.includes('/public/') ||
          path.includes('/external/') ||
          path.includes('/plato/documents/issue') ||
          path === '/patient-dashboard' ||
          path === '/'
        )
          return;

        signOutUser(localStorage.getItem('USER_SAFE_ID'));
        return history.push({ pathname: '/', search: window.location.search });
      }

      if (moment.utc(authToken.expires).isAfter(moment())) {
        if (path === '/') {
          history.push('/results');
        }
        await fetchUserDetails();
      } else if (
        refreshToken &&
        moment.utc(refreshToken.expires).isAfter(moment())
      ) {
        if (
          (path !== '/activation/get' && path !== '/reset-password/get') ||
          path.includes('/patient-form') ||
          path.includes('/public/document') ||
          path.includes('/plato/documents/issue') ||
          path.includes('/p/create-account') ||
          path.includes('/public/document') ||
          path.includes('/external/') ||
          path.includes('/public/')
        )
          await refreshUserToken(window.location.search);

        await fetchUserDetails();
      }
    } catch (error) {}
  };

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem('USER_SAFE_ID');
      if (!userId) return;
      const {
        data: { user },
      } = await idUserService.getCurrentUser();
      window.intercomSettings = {
        name: user?.metadata?.name,
        email: user.email,
        user_id: user.safe_id,
      };
      dispatch(setUser(user));
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Suspense fallback={<LoaderPage />}>
          <Switch>
            <Route
              path="/consumable-req1"
              exact
              component={() => <ConsumablesRequest />}
            />
            <Route
              path="/document/:certificateId"
              exact
              render={() => <PublicCertificateView />}
            />
            <Route path="/d/:tinyId" exact render={() => <TinyUrlView />} />
            <Route
              path="/document/qr/:certificateId"
              exact
              render={() => <PublicQRView />}
            />
            <Route path="/password-reset" exact component={() => <Login />} />
            <Route
              path="/reset-password/get"
              exact
              component={() => <Login />}
            />
            <Route path="/activate" exact component={() => <Login />} />
            <Route path="/activation/get" exact component={() => <Login />} />
            <Route path="/user-activate" exact component={() => <Login />} />
            <Route
              path="/plato/documents/issue"
              exact
              component={() => <PlatoIssue type="medical" />}
            />
            <Route
              path="/create-account-success/:id"
              component={() => <CreateAccountSuccess />}
            />
            <Route
              path="/patient-form/:id"
              exact
              component={() => <PublicPatientForm />}
            />
            <Route
              path="/public/document/:certificateId"
              exact
              component={() => <PublicCertificateView />}
            />
            <Route
              exact
              path="/"
              component={() => (isPatientLogin ? <PatientLogin /> : <Login />)}
            />
            <Route
              path="/p/create-account"
              render={() => <PatientSignupWrapper />}
            />
            {/* <Route path="/p/signup" render={() => <PatientSignupV2 />} /> */}
            <Route
              path="/p/create-account-v1"
              render={() => <PatientSignupV2 />}
            />
            <Route
              path="/p/success/:email"
              exact
              render={() => <PatientSuccessSignup />}
            />
            <Route
              path="/public/react-questionnaire"
              exact
              component={() => <PublicReactHealthQuestions />}
            />
            <Route
              path={[
                '/public/react-questionnaire/patient/:id/success',
                '/public/react-questionnaire/session/:id/success',
              ]}
              exact
              component={() => <ReactFormSuccess />}
            />
            <Route
              path="/patient-dashboard"
              exact
              component={() => <IntegrationPatientDashboard />}
            />
            <Route
              path="/public/health-questionnaire"
              exact
              component={() => <PublicQuestionnaire />}
            />
            <Route
              path="/public/health-questionnaire/success"
              exact
              component={() => <PemeSuccessPage />}
            />

            <Route path="/external">
              <div className="rhce-w">
                <div className="rhce">
                  <Switch>
                    <Route
                      path="/external/react-health"
                      exact
                      component={() => <ReactHealthContainerExternal />}
                    />
                    <Route
                      path="/external/react-health/:id"
                      exact
                      component={() => <ReactHealthDetails />}
                    />
                    <Route
                      path="/external/react-health/patient/:id"
                      exact
                      component={() => <PatientReactSessionDetails />}
                    />
                    <Route
                      path="/external/patients"
                      exact
                      component={() => <PatientsFhir />}
                    />
                  </Switch>
                </div>
              </div>
            </Route>

            {/* TODO: render them based on user role */}
            {userInfo?.metadata?.role === 'patient' && (
              <Route path="/" render={() => <PatientRoutes />} />
            )}
            {!!userInfo?.metadata?.role &&
              userInfo?.metadata?.role !== 'patient' && (
                <Route path="/" render={() => <ClientRoutes />} />
              )}
            <Redirect to="/" />
          </Switch>
        </Suspense>
      )}
    </>
  );
};
